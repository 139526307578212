import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loader: false,
  isAuth: false,
};

export const forgotSlice = createSlice({
  name: "forgot",
  initialState,
  reducers: {
    forgotAction: (state, action) => {
      state.loader = true;
    },

    forgotActionSuccess: (state, action) => {
      state.loader = false;
      state.user = action.payload;
      state.isAuth = true;
    },
    forgotActionError: (state) => {
      state.loader = false;
      state.user = null;
      state.isAuth = false;
    },
  },
});

export const {
  forgotAction, forgotActionError, forgotActionSuccess
} = forgotSlice.actions;
