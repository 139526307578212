import React from "react";
import "./SignInModal.scss";
import Modal from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";
import CheckBox from "../../../../components/core/CheckBox";

const SignInModal = ({ onSubmit, register, errors, isLoading, showSignUpModal, showForgotPasswordModal, isValid }) => {

  return <Modal shouldCloseOnOverlayClick={false} id={"signInModal"} title={"Sign in"}>

    <form autoComplete="off">
      <Input
        error={errors.email?.message}
        register={register}
        placeholder="Email"
        name="email"
        id="loginId"
      />
      <Input
        error={errors.password?.message}
        register={register}
        placeholder="Password"
        name="password"
        type="password"
        id="passwordInputId"
      />
      <div className={'px-16 lg:px-0'}>
        <CheckBox register={register} name={"isRemember"} label={"Remember me"} className={"mt-28 lg:mt-36 ml-10 lg:ml-0"}></CheckBox>
        <Button
          color={'link'}
          className={'mt-15 ml-10 lg:ml-0'}
          onClick={showForgotPasswordModal}
        >
          Forgot your password?
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading  || !isValid}
          onClick={onSubmit}
          type="submit"
          className={"w-full mt-32"}
        >
          Sign in
        </Button>
        <Button
          className={"w-full mt-20"}
          color={"secondary"}
          size={"small"}
          onClick={showSignUpModal}
        >
          Don’t have an account? Create one here!
        </Button>
      </div>
    </form>
  </Modal>;


};

SignInModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  showForgotPasswordModal: PropTypes.func.isRequired,
  showSignUpModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  isValid: PropTypes.any.isRequired
};

export default SignInModal;
