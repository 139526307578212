import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  isNotActivatedUser: false,
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerAction: (state, action) => {
      state.loader = true;
    },

    registerActionSuccess: (state, action) => {
      state.loader = false;
      state.isNotActivatedUser = action.payload;
    },
    registerActionError: (state) => {
      state.loader = false;
      state.isNotActivatedUser = null;
    },
  },
});

export const {
  registerActionError,
  registerActionSuccess,
  registerAction,
} = registerSlice.actions;
