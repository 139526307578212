import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./Modal.scss";

import { useOnClickOutside } from "../../../hooks";
import Portal from "../Portal";
import ModalHeader from "./ModalHeader";
import { hideModal, showModal } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";

export const Modal = ({
  id,
  title,
  children,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  hasCloseButton,
  className,
  positionBottom,
  ...rest
}) => {
  const [modalData, setModalData] = useState(false);
  const [modal, setModal] = useState();
  const [modalCover, setModalCover] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    function handleShow(e) {
      const { id: dispatchedId, data } = e.detail;
      if (id === dispatchedId) {
        setModalData(data || {});
      }
      document.body.style.overflow = 'hidden';
    }

    function handleHide(e) {
      const { id: dispatchedId } = e.detail;
      if (id === dispatchedId) {
        setModalData(false);
      }
      document.body.style.overflow = 'unset';
    }

    // eslint-disable-next-line no-undef
    document.addEventListener("showModal", handleShow);
    // eslint-disable-next-line no-undef
    document.addEventListener("hideModal", handleHide);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("showModal", handleShow);
      // eslint-disable-next-line no-undef
      document.removeEventListener("hideModal", handleHide);
    };
  }, [id]);

  useEffect(() => {
    if (modalCover) {
      modalCover.focus();
    }
  }, [modalCover]);

  const closeModal = useCallback(() => {
    hideModal(id);
    navigate(location.pathname);

  }, [id, location.pathname, navigate]);

  const handleKeyDown = useCallback(
    (event) => {
      if (!shouldCloseOnEsc) {
        return;
      }
      if (event.keyCode === 27) {
        closeModal();
      }
    },
    [shouldCloseOnEsc, closeModal]
  );

  useOnClickOutside({ current: modal }, () => {
    if (shouldCloseOnOverlayClick) {
      closeModal();
    }
  });

  if (!modalData) return null;

  return (
    <Portal>
      {modalData && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
          role="dialog"
          tabIndex="-1"
          aria-modal="true"
          className={cx(`modalCover`, positionBottom && 'positionBottom')}
          onKeyDown={handleKeyDown}
          ref={setModalCover}
        >
          <div className={cx(`modalArea`, className)} ref={setModal} {...rest}>
            {(title || hasCloseButton) && (
              <ModalHeader closeModal={closeModal} hasCloseButton={hasCloseButton}>
                {title}
              </ModalHeader>
            )}
            <div className={`body`}>
              {typeof children === "function" ? children({ modalData }) : children}
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

Modal.defaultProps = {
  title: undefined,
  children: undefined,
  className: undefined,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: false,
  hasCloseButton: true,
  positionBottom: false,
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  positionBottom: PropTypes.bool,
};

Modal.hide = hideModal;
Modal.show = showModal;

export default Modal;
