import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Home.scss";
import Button from "../../components/core/Button";
import hero from "../../assets/images/hero.png";
import advantagesIcon_1 from "../../assets/images/advantages/Icon1.svg";
import advantagesIcon_2 from "../../assets/images/advantages/Icon2.svg";
import advantagesIcon_3 from "../../assets/images/advantages/Icon3.svg";
import api from "../../assets/images/api.jpg";
import btcpay from "../../assets/images/btcpay.svg";
import fingerprintBg from "../../assets/images/fingerprint-left.svg";
import PC from "../../components/core/Icons/PC";
import Windows from "../../components/core/Icons/Windows";
import Edge from "../../components/core/Icons/Edge";
import Download from "../../components/core/Icons/Download";
import Phone from "../../components/core/Icons/Phone";
import Android from "../../components/core/Icons/Android";
import Mac from "../../components/core/Icons/Mac";
import Chrome from "../../components/core/Icons/Chrome";
import Firefox from "../../components/core/Icons/Firefox";
import Safari from "../../components/core/Icons/Safari";
import Pack from "../../components/Pack/Pack";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../SignIn";
import { show } from "../../components/core/Modal";
import { addInvoice, getApiKey, getFree } from "../Pricing";
import { homePageListSelector } from "../Database/redux/selectors";
import { getHomePageList, getSingleCookiesFingerPrints, getSingleFingerPrints } from "../Database";
import { HOME_PAGE_MARKS } from "../../constants/constants";
import DownloadCookies from "../../components/core/Icons/DownloadCookies";

const Home = () => {
  const [packCount, setPackCount] = useState(10);
  const [packColor, setPackColor] = useState("free");
  const [packPerPrice, setPackPerPrice] = useState(0);
  const [packBordered, setPackBordered] = useState(true);
  const [packActiveFeatures, setPackActiveFeatures] = useState([0]);
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const navigate = useNavigate()
  const location = useLocation();
  const tableList = useSelector(homePageListSelector)
  const invoice = new URLSearchParams(location.search).get("invoice");
  useEffect(() => {
    if (invoice) {
      dispatch(addInvoice({invoiceId: invoice}))

    }
  }, [dispatch, invoice]);


  useEffect(() => {
    dispatch(getHomePageList())
  }, [dispatch, user])
  const handleSliderChange = (value) => {
    const correctValue = HOME_PAGE_MARKS[value].value;
    setPackCount(correctValue);
    if (correctValue >= 10000) {
      setPackColor("pro");
      setPackActiveFeatures([0, 1]);
      setPackBordered(false);
      setPackPerPrice(1);
    } else if (correctValue > 10) {
      setPackColor("basic");
      setPackActiveFeatures([0, 1]);
      setPackBordered(false);
      setPackPerPrice(2);
    } else if (correctValue >= 0) {
      setPackColor("free");
      setPackActiveFeatures([0]);
      setPackBordered(true);
    }
  };

  const goSignIn = () => {
    navigate(`${location.pathname}?signIn`)
    show("signInModal")
  }

  const onGetApiKey = useCallback(() => {
   dispatch(getApiKey())
  }, [dispatch])

  const handleOsIcon = useCallback((data) => {
    switch (data?.os) {
      case "iOS":
        return <Mac />;
      case "Windows":
        return <Windows/>;
      case "Android":
        return <Android />;
      case "Linux":
        return <Windows />;
      default:
        return <Mac />;
    }

  }, [])


  const handleBrowserIcon = useCallback((data) => {
    if (data?.browser.includes("Safari")) {
      return  <Safari />
    }
    if (data?.browser.includes("Chrome")) {
      return  <Chrome />
    }
    if (data?.browser.includes("Edge")) {
      return  <Edge />
    }
    if (data?.browser.includes("Firefox")) {
      return <Firefox />
    } else {
      return  <Chrome />
    }

  }, [])

  const onSubmitDownload = useCallback((id) => {
    if (!user) {
      navigate(`${location.pathname}?signIn`)
      show("signInModal")
    } else if (user && user?.balance > 0) {
      dispatch(getSingleFingerPrints({ id: id, cookie: "mix" }))
    } else {
      show('buyPackModal')
    }

  }, [dispatch, location.pathname, navigate, user])

  const onSubmitCookiesDownload = useCallback((id) => {
    if (!user) {
      navigate(`${location.pathname}?signIn`)
      show("signInModal")
    } else if (user && user?.balance > 0) {
      dispatch(getSingleCookiesFingerPrints({ id: id, cookie: "mix" }))
    } else {
      show('buyPackModal')
    }

  }, [dispatch, location.pathname, navigate, user])
  return (
    <>
      <section className={"home-banner"}>
        <div className={"design-elements"}>
          <img src={fingerprintBg} alt={""} />
          <div className={"elipse"}></div>
        </div>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-12 lg:col-7 pr-0"}>
              <h1>Buy validated browser profiles<br /><span className={"text-light h2 lg:h1"}>and stay one step ahead of online trackers</span>
              </h1>
              <h4 className={"text-light"}>Possessing Unique browser fingerprints guarantees your security, while opting for ready-to-use Cookies offers a level of convenience that cannot be matched by a cookie robot.</h4>
              {!user && <Button onClick={goSignIn}>Get started</Button>}
              {/*{user && <Button onClick={() => {*/}
              {/*  if (user?.free_credits) {*/}
              {/*    navigate("/pricing");*/}
              {/*  } else {*/}
              {/*    dispatch(getFree())*/}
              {/*  }*/}
              {/*}}>{user?.free_credits ? "Need more credits?" : "Get started"}</Button>}*/}
            </div>
            <div className={"col-12 lg:col-5 pl-0"}>
              <img src={hero} alt={"Buy unique fingerprints"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"why-xpass"}>
        <div className={"design-elements"}>
          <img src={fingerprintBg} alt={""} />
        </div>
        <div className={"container"}>
          <p className={"h1 section-title"}>Why XPASS? <br /> <span className={"text-light"}>Our Advantages</span></p>
          <div className={"row-slider lg:row"}>
            <div className={"col lg:col-4"}>
              <>
                <div className={"advantages-item"}>
                  <img src={advantagesIcon_1} alt={"Unique fingerprints for different OS/Browsers"} />
                  <p className={"title"}>Unique fingerprints for different OS/Browsers</p>
                </div>
              </>
            </div>
            <div className={"col lg:col-4"}>
              <>
                <div className={"advantages-item"}>
                  <img src={advantagesIcon_2} alt={"Easy Bitcoin payments"} />
                  <p className={"title"}>Easy<br />Bitcoin payments</p>
                </div>
              </>
            </div>
            <div className={"col lg:col-4"}>
              <>
                <div className={"advantages-item"}>
                  <img src={advantagesIcon_3} alt={"Instant access to verified fingerprint API"} />
                  <p className={"title"}>Over 100000 browser profiles with pre-made Cookies</p>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>

      <section className={"databases-section"}>
        <div className={"container"}>
          <p className={"h1 section-title"}><span className={"text-light"}>Get access to a live database of</span><br />over
            8 million fingerprints</p>

          <div className={"responsive-table"}>
            <table>
              <thead>
              <tr>
                <th>Device type</th>
                <th>OS</th>
                <th>Browser</th>
                <th>Screen Resolution</th>
                <th>WebGL</th>
                <th>Cookie</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {tableList && tableList.map((fingerPrint, index) => {
                return <tr key={index}>
                  <td>{fingerPrint?.device_type === "mobile" ? <Phone/>:<PC/>} {fingerPrint?.device_type}</td>
                  <td> {handleOsIcon(fingerPrint)} {fingerPrint?.os}</td>
                  <td>{handleBrowserIcon(fingerPrint)} {fingerPrint?.browser}</td>
                  <td>{fingerPrint?.resolution}</td>
                  <td className={"webgl"}>{fingerPrint?.webgl}</td>
                  <td className={"webgl"}>Google/Facebook</td>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <td className={"download_icon_cookies"} onClick={() => onSubmitCookiesDownload(fingerPrint?.id) }><DownloadCookies /></td>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                   <td className={"download_icon_profile"} onClick={() => onSubmitDownload(fingerPrint?.id)}><Download /></td>
                </tr>
              })}
              </tbody>
            </table>
          </div>

            <Button onClick={() => {
              if (!user) {
                navigate(`${location.pathname}?signIn`)
                show("signInModal")
              } else {
                navigate("/database")
              }
            }} color={"secondary"}>Browse the Entire Database</Button>
        </div>
      </section>

      <section className={"api-section"}>
        <div className={"container"}>
          <p className={"h1 section-title lg:hidden"}>API <br /><span className={"text-light"}>For Developers</span></p>
          <div className={"row"}>
            <div className={"col-12 lg:col-6 order-2 lg:order-1"}>
              <p className={"h1 section-title hidden lg:block"}>API <br /><span
                className={"text-light"}>For Developers</span></p>
              <div className={"api-section-content"}>
                <p className={"p1 text-light"}>Proceed to the payment and get access to the precise API database of
                  fingerprints.</p>
                <ul>
                  <li>Your API key will be sent to your email address.</li>
                  <li>Go ahead and start using it!</li>
                </ul>
              </div>
              <div className={"flex flex-column space-x-16 lg:space-x-32"}>
                <Button onClick={() => navigate("/api-reference")} color={"secondary"}>API Reference</Button>
                <Button onClick={() => {
                  if (!user) {
                    goSignIn()
                  } else {
                    onGetApiKey()
                  }
                }} color={"primary"}>Get Your key</Button>
              </div>
            </div>
            <div className={"col-12 lg:col-6 order-1 lg:order-2"}>
              <div className={"api-section-image"}>
                <div className={"design-elements"}>
                  <div className={"elipse"}></div>
                </div>
                <img src={api} alt={""} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className={"get-started-section"}>*/}
      {/*  <div className={"design-elements"}>*/}
      {/*    <img src={fingerprintBg} alt={""} />*/}
      {/*  </div>*/}
      {/*  <div className={"container"}>*/}
      {/*    <div className={"row"}>*/}
      {/*      <div className={"col-12 lg:col-4 lg:push-1"}>*/}
      {/*        <p className={"h2 section-title"}>Get Started for Free <br /><span className={"text-light"}>No Credit Card Required</span>*/}
      {/*        </p>*/}
      {/*        <div className={"range-slider"}>*/}
      {/*          <p>How many fingerprints do you need?</p>*/}
      {/*          <Slider min={1} marks={HOME_PAGE_MARKS} step={null} onChange={handleSliderChange} defaultValue={1}  max={11}/>*/}
      {/*        </div>*/}
      {/*        <div className={"btcpay-info items-center hidden lg:flex"}>*/}
      {/*          <p>All payments are accepted only in Bitcoin (BTC) and are processed by BTCPAY</p>*/}
      {/*          <img src={btcpay} alt={""} />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={"col-12 lg:col-4 lg:push-2"}>*/}
      {/*        <Pack*/}
      {/*          packType={"static"}*/}
      {/*          color={packColor}*/}
      {/*          defaultCount={packCount}*/}
      {/*          activeFeatures={packActiveFeatures}*/}
      {/*          perPrice={packPerPrice}*/}
      {/*          options={[]}*/}
      {/*          bordered={packBordered} />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={"btcpay-info flex items-center lg:hidden mt-24"}>*/}
      {/*      <p>All payments are accepted only in Bitcoin (BTC) and are processed by BTCPAY</p>*/}
      {/*      <img src={btcpay} alt={""} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </>
  );
};
export default Home;
