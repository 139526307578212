import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updatePasswordAction: (state, action) => {
      state.loader = true;
    },

    updatePasswordSuccess: (state, action) => {
      state.loader = false;
    },
    updatePasswordError: (state) => {
      state.loader = false;
    },

    updateProfileAction: (state) => {
      state.loader = false;
    },

    updateProfileSuccess: (state) => {
      state.loader = false;
    },
    updateProfileError: (state) => {
      state.loader = false;
    },
  },
});

export const {
  updatePasswordAction,
  updatePasswordError,
  updatePasswordSuccess,
  updateProfileAction,
  updateProfileError,
  updateProfileSuccess
} = accountSlice.actions;
