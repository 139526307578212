import { call, put, takeEvery } from "@redux-saga/core/effects";
import authService from "../services/authService";

import {
  getUserAction,
  logOutAction,
  resetState, sendEmailAction, sendEmailError, sendEmailSuccess,
  signInAction,
  signInActionError,
  signInActionSuccess
} from "./signInSlice";
import { toast } from "react-toastify";
import { hide } from "../../../components/core/Modal";


function * signInSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        authService.signIn({email: payload.data.email, password: payload.data.password}),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(signInActionError());
    } else {

      yield put(signInActionSuccess(response.payload.user));
      if (!payload.data.isRemember) {
        sessionStorage.setItem("token", response.payload.user.token)
      } else {
        localStorage.setItem("token", response.payload.user.token)
      }
    hide("signInModal")
    }

  } catch (error) {
    yield put(signInActionError());
  }
}

function * getUserSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        authService.getUser(),
    );

    if (response?.hasError) {
      // toast.error(response.errors[0])
      yield put(signInActionError());
    } else {
      yield put(signInActionSuccess(response.payload.user));
    }

  } catch (error) {
    yield put(signInActionError());
  }
}

function * sendEmailSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        authService.sendEmail(payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(sendEmailError());
    } else {
      yield put(sendEmailSuccess());
      toast.success("Your email has been successfully sent")

    }

  } catch (error) {
    yield put(sendEmailError());
  }
}

function * logOutSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        authService.logOut(),
    );

    if (response?.hasError) {
      // toast.error(response.errors[0])
      // yield put(signInActionError());
    } else {
      localStorage.removeItem("token")
      sessionStorage.removeItem("token")
      yield put(resetState());
    }

  } catch (error) {
    yield put(signInActionError());
  }
}

export function * watchFetchAuth () {
  yield takeEvery(signInAction.type, signInSaga);
  yield takeEvery(getUserAction.type, getUserSaga);
  yield takeEvery(logOutAction.type, logOutSaga);
  yield takeEvery(sendEmailAction.type, sendEmailSaga);
}

