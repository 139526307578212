import { call, put, takeEvery } from "@redux-saga/core/effects";
import registerService from "../services/verifyService";
import { verifyAction, verifyActionError } from "./verifySlice";
import { hide, show } from "../../../components/core/Modal";
import { getUserAction } from "../../SignIn";
import { toast } from "react-toastify";


function * verifySaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        registerService.register(payload.data),
    );
    if (response?.hasError) {
      yield put(verifyActionError());
    } else {
      payload.navigate(`${payload.location.pathname}`);
      sessionStorage.setItem("token", response.payload.token)
      hide("verifyEmailModal")
      show("joinTelegramModal")
      yield put(getUserAction());
      toast.success("Your email has been successfully verified")
    }

  } catch (error) {
    yield put(verifyActionError());
  }
}

export function * watchFetchVerify () {
  yield takeEvery(verifyAction.type, verifySaga);
}

