import ServiceBase from "../../../services/apiServiceBase";

class AccountService extends ServiceBase {
  async updatePassword (request) {
    return await this.put(`${this.apiUrl}/user/update-password`, request);
  }
  async updateProfile (request) {
    return await this.put(`${this.apiUrl}/user/update`, request);
  }

}
export default new AccountService();
