import React from "react";
import "./GetApiKeySuccessModal.scss";
import Modal, { hide } from "../core/Modal";
import Button from "../core/Button";
import EmailSuccess from "../core/Icons/EmailSuccess";

const GetApiKeySuccessModal = () => {
  return <Modal hasCloseButton={false} id={`getApiKeySuccessModal`}>
    <div className={'success-text'}>
      <EmailSuccess />
      <p>Your API key has been sent to your account email.</p>
      <Button size={"small"} color={"secondary"} onClick={() => {
        hide(`getApiKeySuccessModal`);
      }}>Okay</Button>
    </div>
  </Modal>;
};
export default GetApiKeySuccessModal;
