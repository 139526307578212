import React, { useState } from "react";
import "./BuyPackModal.scss";
import Modal from "../../../../components/core/Modal";
import PropTypes from "prop-types";
import btcpay from "../../../../assets/images/btcpay.svg";
import Pack from "../../../../components/Pack/Pack";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { BUY_PACK_MARKS } from "../../../../constants/constants";

const BuyPackModal = () => {
  const [packCount, setPackCount] = useState(30);
  const [packColor, setPackColor] = useState("basic");
  const [packPerPrice, setPackPerPrice] = useState(2);
  const [packBordered, setPackBordered] = useState(false);
  const [packActiveFeatures, setPackActiveFeatures] = useState([0]);

  const handleSliderChange = (value) => {
    const correctValue = BUY_PACK_MARKS[value].value;
    setPackCount(correctValue);
    if (correctValue >= 1000) {
      setPackColor("pro");
      setPackActiveFeatures([0, 1]);
      setPackBordered(false);
      setPackPerPrice(1);
    } else if (correctValue > 10) {
      setPackColor("basic");
      setPackActiveFeatures([0, 1]);
      setPackBordered(false);
      setPackPerPrice(2);
    }
  };
  return <Modal id={"buyPackModal"} title={""} className={'buyPackModal'}>
    <div className={"row"}>
      <div className={"col-12 lg:col-6"}>
        <p className={"h2 section-title"}>Buy Additional Credits</p>
        <div className={"range-slider"}>
          <p>It seems like you have used all your available credits. Please purchase additional downloads to get access to more fingerprints</p>
          <Slider
            min={1}
            marks={BUY_PACK_MARKS}
            step={null}
            onChange={handleSliderChange}
            defaultValue={1}
            max={10}/>
        </div>
        <div className={"btcpay-info items-center hidden lg:flex"}>
          <p>All payments are accepted only in Bitcoin (BTC) and are processed by BTCPAY</p>
          <img src={btcpay} alt={""} />
        </div>
      </div>
      <div className={"col-12 lg:col-6"}>
        <Pack
          packType={"static"}
          color={packColor}
          defaultCount={packCount}
          activeFeatures={packActiveFeatures}
          perPrice={packPerPrice}
          options={[]}
          bordered={packBordered}
          noDetails={true}/>
      </div>
    </div>
    <div className={"btcpay-info flex items-center lg:hidden mt-24"}>
      <p>All payments are accepted only in Bitcoin (BTC) and are processed by BTCPAY</p>
      <img src={btcpay} alt={""} />
    </div>
  </Modal>;
};

export default BuyPackModal;
