import React from "react";
const Avatar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.00195C8.8185 3.00195 7.5 5.0427 7.5 7.50195C7.5 8.32995 7.89551 9.16162 7.89551 9.16162C7.73651 9.25237 7.47502 9.54282 7.54102 10.0596C7.66402 11.0218 8.08118 11.2669 8.34668 11.2871C8.44793 12.1849 9.4125 13.3332 9.75 13.5005V15.0005C9.68775 15.1887 9.57724 15.3421 9.44824 15.4839C10.0767 16.0261 10.9305 16.5005 12 16.5005C13.0695 16.5005 13.9233 16.0261 14.5518 15.4839C14.4228 15.3421 14.3123 15.1887 14.25 15.0005V13.5005C14.5875 13.3332 15.5521 12.1849 15.6533 11.2871C15.9188 11.2669 16.336 11.0218 16.459 10.0596C16.525 9.54282 16.2635 9.25312 16.1045 9.16162C16.1045 9.16162 16.5 8.4102 16.5 7.50195C16.5 5.68095 15.7852 4.12695 14.25 4.12695C14.25 4.12695 13.7168 3.00195 12 3.00195ZM8.07129 16.2559C6.04854 16.9429 3 17.255 3 21.0005H21C21 17.255 17.9515 16.9429 15.9287 16.2559C15.0737 17.1221 13.773 18.0005 12 18.0005C10.227 18.0005 8.92629 17.1221 8.07129 16.2559Z"
        fill="#64627A" />
    </svg>
  )
}
export default Avatar;
