import ServiceBase from "../../../services/apiServiceBase";

class ResetService extends ServiceBase {
  async reset(request) {
    return await this.post(`${this.apiUrl}/user/reset-password`, request);
  }

}

export default new ResetService();
