import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Button.scss";

const Button = ({
  children,
  onClick,
  mode,
  loading,
  disabled,
  rounded,
  color,
  size,
  className,
  id,
  name,
  type,
  tabIndex,
  ...rest
}) => {
  const buttonClass = cx('btn', className, {
    'btn-disabled': disabled || color === "disabled",
    'btn-primary': color === "primary",
    'btn-secondary': color === "secondary",
    'btn-link': color === "link",
    'btn-small': size === "small",
    'btn-large': size === "large",
  });

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || "button"}
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      id={id}
      name={name}
      tabIndex={tabIndex}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  children: null,
  onClick: () => {},
  color: "primary",
  size: "regular",
  mode: "contained",
  rounded: false,
  loading: false,
  disabled: false,
  className: null,
  id: null,
  name: null,
  tabIndex: null,
  type: "button",
};

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["primary", "secondary", "disabled", "link"]),
  size: PropTypes.oneOf(["small", "regular", "large"]),
  mode: PropTypes.oneOf(["outline", "contained", "link"]),
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Button;
