import React from "react";
import "./InfoModal.scss";
import Modal, { hide } from "../core/Modal";
import Button from "../core/Button";
import PropTypes from "prop-types";
import EmailSuccess from "../core/Icons/EmailSuccess";

const InfoModal = ({title, description }) => {
  return <Modal  id={`infoModalEmail`}>
    <div className={'success-text'}>
      {title && <h4>{title}</h4> }
      <EmailSuccess />
      {description && <p>{description}</p>}
      <Button size={"small"} color={"secondary"} onClick={() => {
        hide(`infoModalEmail`);
      }}>Okay</Button>
    </div>
  </Modal>;
};

InfoModal.defaultProps = {
  title: null,
  description: null,
};

InfoModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default InfoModal;
