import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import SignInContainer, {
  getUserAction,
  isAuthSelector,
  logOutAction,
  userSelector
} from "../../modules/SignIn";
import SignUpContainer from "../../modules/Registration";
import Button from "../core/Button";
import { show } from "../core/Modal";
import "./MainLayout.scss";
import logo from "../../assets/images/logo.svg";
import SetPasswordContainer from "../../modules/SetPassword";
import VerifyEmailContainer from "../../modules/VerifyEmail";
import ResendCodeContainer from "../../modules/ResendCode";
import Burger from "../core/Icons/Burger";
import XIcon from "../core/Icons/XIcon";
import ForgotPasswordContainer from "../../modules/ForgotPassword";
import Avatar from "../core/Icons/Avatar";
import HistoryIcon from "../core/Icons/History";
import Lock from "../core/Icons/Lock";
import Logout from "../core/Icons/Logout";
import BuyPackContainer from "../../modules/BuyPack";
import SuccessModal from "../SuccessModal";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordContainer from "../../modules/ResetPassword";
import InfoModal from "../InfoModal";
import GetApiKeySuccessModal from "../GetApiKeySuccessModal";
import Bil from "../core/Icons/Bil";
import Footer from "../../modules/Footer";
import DownloadSuccessModal from "../DownloadSuccessModal";
import JoinTelegram from "../../modules/JoinTelegram";

const MainLayout = () => {
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuth = useSelector(isAuthSelector)
  const user = useSelector(userSelector)

  const [stickyHeader, setStickyHeader] = useState(false);
  const [active, setActive] = useState(false);
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);

const token = localStorage.getItem("token") || sessionStorage.getItem("token")
  const showSignInModal = useCallback(() => {
    navigate(`${location.pathname}?signIn`)
    show("signInModal");
  }, [location.pathname, navigate]);

  useEffect(() => {
    const isSticky = () => {
      window.scrollY >= 50 ? setStickyHeader(true) : setStickyHeader(false);
    };
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });


  useEffect(() => {
    if (token) {
      dispatch(getUserAction())

    }
  }, [dispatch, token])


  useEffect(() => {
    setAvatarDropdownOpen(false);
    setActive(false);
    window.scrollTo(0,0);
  }, [location]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (avatarDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setAvatarDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [avatarDropdownOpen]);
  const showMenu = () => {
    setActive(!active);
  };

  return (
    <>
      <SignInContainer />
      <ResetPasswordContainer />
      <SignUpContainer />
      <SetPasswordContainer />
      <VerifyEmailContainer />
      <JoinTelegram />
      <ResendCodeContainer />
      <ForgotPasswordContainer />
      <BuyPackContainer />
      <GetApiKeySuccessModal />
      <DownloadSuccessModal />
      <SuccessModal icon={'email'} description={'We have sent you an email to reset your password. Please open your email and follow the instructions.'} />
      <InfoModal title={"Verification email sent!"} description={'Please check your inbox and follow the instructions.'} />
      <header className={`header-section ${stickyHeader ? "is-sticky" : ""}`}>
        <nav className="nav nav-default">
          <div className="container px-16 flex flex-wrap items-center mx-auto">
            <button data-collapse-toggle="navbar-default" type="button"
                    className="inline-flex items-center md:hidden navbar-toggle mr-16"
                    aria-controls="navbar-default" aria-expanded="false"
                    onClick={showMenu}>
              <span className="sr-only">Open main menu</span>
              {active && <XIcon />}
              {!active && <Burger />}
            </button>
            <Link to="/" className="flex items-center navbar-brand">
              <img src={logo} alt={"XPASS"} className="mr-16 navbar-brand-logo" />
            </Link>
            <div className={(active ? "active" : "invisible") + " w-full md:visible md:w-auto nav-collapse ml-auto"}
                 id="navbar-default">
              <div className="design-elements md:hidden">
                <div className="design-elements-b"></div>
                <div className="design-elements-b-half"></div>
                {/*<Circles className="menu"></Circles>*/}
              </div>
              <ul className="flex flex-col md:flex-row md:space-x-16">
                {user && <li className="delay-1">
                  <NavLink to={"/database"}
                           className={`block`}>Database</NavLink>
                </li>}
                <li className="delay-2">
                  <NavLink to={"/api-reference"}
                           className={`block`}>API Reference</NavLink>
                </li>
                <li className="delay-3">
                  <NavLink to={"/pricing"}
                           className={`block`}>Pricing</NavLink>
                </li>
                <li className="delay-3">
                  <Link className={`block`} target={"_blank"} to={"https://xpass.pro/blog"}>Blog</Link>
                </li>
                {/*<li className="delay-4">*/}
                {/*  <HashLink to={"#footer"}*/}
                {/*            className={`block`} smooth>Contact Us</HashLink>*/}
                {/*</li>*/}
              </ul>
            </div>
            {!isAuth &&
              <Button className={"ml-auto md:ml-36"} color={"primary"} size={"small"} onClick={showSignInModal}>Sign
                In</Button>}
            {isAuth &&
              <div className={`ml-auto md:ml-36 dropdown-avatar`} ref={ref}>
                <button className={"avatar-button"} onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}>
                  <p className={"avatar-button-downloads"}>Downloads left: <span>{user?.balance}</span></p>
                  <p className={"avatar-button-avatar"}>{user.first_name.charAt(0).toUpperCase()}{user.last_name.charAt(0).toUpperCase()}</p>
                </button>
                <div className={`dropdown-avatar-block ${avatarDropdownOpen && "active"}`}>
                  <div className={"dropdown-avatar-block-info"}>
                    <p className={"dropdown-avatar-block-info-title"}>My Fingerprints</p>
                    <div className={"dropdown-avatar-block-info-details"}>
                      <div className={"dropdown-avatar-block-info-detail"}>
                        <span>Downloaded</span>
                        <span>{user?.alreadyDownloaded}</span>
                      </div>
                      <div className={"dropdown-avatar-block-info-detail"}>
                        <span>Remaining</span>
                        <span>{user?.balance}</span>
                      </div>
                    </div>
                    <Button size={"small"} onClick={() => show('buyPackModal')}>Buy More Credits</Button>
                  </div>
                  <nav className={"dropdown-avatar-block-menu"}>
                    <NavLink to={"/account/"}>
                      <Bil />
                      <span>Billing</span>
                    </NavLink>
                    <NavLink to={"/account/information"}>
                      <Avatar />
                      <span>Personal information</span>
                      {/*<span className={'notification'}>1</span>*/}
                    </NavLink>
                    <NavLink to={"/account/history"}>
                      <HistoryIcon />
                      <span>Downloads History</span>
                      {/*<span className={'notification'}>1</span>*/}
                    </NavLink>
                    <NavLink to={"/account/password"}>
                      <Lock />
                      <span>Change Password</span>
                    </NavLink>
                    <NavLink onClick={() => dispatch(logOutAction())} to={"/"}>
                      <Logout />
                      <span>Log Out</span>
                    </NavLink>
                  </nav>
                </div>
              </div>
            }
          </div>
        </nav>
      </header>

      <Outlet />
      <Footer/>

      <ToastContainer position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      draggable
                      theme="dark" />

    </>
  );
};

export default MainLayout;
