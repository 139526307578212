import { useEffect } from "react";

const defaultOptions = {
  ignoreClassNames: [],
  fireOnInsideClick: false,
};

export default function useOnClickOutside(
  ref,
  handler,
  { ignoreClassNames, fireOnInsideClick } = defaultOptions
) {
  useEffect(() => {
    const listener = (event) => {
      const shouldBeIgnored =
        ignoreClassNames && ignoreClassNames.some((cls) => !!event.target.closest(`.${cls}`));

      if (
        !ref.current ||
        (!fireOnInsideClick && ref.current.contains(event.target)) ||
        shouldBeIgnored
      ) {
        return;
      }

      handler(event);
    };

    // eslint-disable-next-line no-undef
    document.addEventListener("mousedown", listener);
    // eslint-disable-next-line no-undef
    document.addEventListener("touchstart", listener);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("mousedown", listener);
      // eslint-disable-next-line no-undef
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, ignoreClassNames, fireOnInsideClick]);
}
