import React, { useEffect } from "react";
import SignUpModal from "../components/SignUpModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {useCallback} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { hide, show } from "../../../components/core/Modal";

const schema = yup.object().shape({
    email: yup.string().matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    ).required("Email is required"),
    name: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required")
});
const SignUpContainer = () => {
    // const isLoading = useSelector(isSignInLoader)
    const navigate = useNavigate();
    const location = useLocation();
    const isLoading = false;
    const {register, handleSubmit,resetField,
        formState: {
            errors,
          isValid
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (location.search.includes("createAccount")) {
            resetField("email")
            resetField("name")
            resetField("lastName")
        }

    },[location, resetField])
    const onSubmit = useCallback((data) => {
        navigate(`${location.pathname}?setPassword`);
         hide("signUpModal");
        show("setPasswordModal", data);
    },[location.pathname, navigate]);

    return <SignUpModal isValid={isValid} isLoading={isLoading} errors={errors} register={register} onSubmit={handleSubmit(onSubmit)}/>
}

export default SignUpContainer
