import ServiceBase from "../../../services/apiServiceBase";

class AuthService extends ServiceBase {
  async signIn(request) {
    return await this.post(`${this.apiUrl}/user/login`, request);
  }

  async sendEmail(request) {

    return await this.post(`${this.apiUrl}/user/contact-us`, request);
  }
  async getUser() {
    return await this.get(`${this.apiUrl}/user/me`);
  }

  async logOut() {
    return await this.post(`${this.apiUrl}/user/logout`, {});
  }

}

export default new AuthService();
