import { all } from "@redux-saga/core/effects";
import { watchFetchAuth } from "../modules/SignIn";
import { watchFetchRegister } from "../modules/SetPassword";
import { watchFetchVerify } from "../modules/VerifyEmail";
import { watchFetchForgot } from "../modules/ForgotPassword";
import { watchFetchReset } from "../modules/ResetPassword";
import { watchFetchAccount } from "../modules/Account";
import { watchFetchDataBase } from "../modules/Database";
import { watchFetchPrice } from "../modules/Pricing";
import { watchFetchResendCode } from "../modules/ResendCode";
export default function* rootSaga() {
    yield all([
        watchFetchAuth(),
        watchFetchRegister(),
        watchFetchVerify(),
        watchFetchForgot(),
        watchFetchReset(),
        watchFetchAccount(),
        watchFetchDataBase(),
        watchFetchPrice(),
        watchFetchResendCode(),
    ]);
}
