import React from "react";
import "./DownloadSuccessModal.module.scss";
import Modal, { hide } from "../core/Modal";
import DownloadBig from "../core/Icons/DownloadBig";
import Button from "../core/Button";

const DownloadSuccessModal = () => {
  return <Modal hasCloseButton={false} id={`downloadSuccessModal`}>
    {({modalData}) => {
      return (
        <div className={'success-text'}>
          <h4>Your download is on its way</h4>
          <DownloadBig />
          {modalData?.imModalData ? <p>Thank you. We will send the results to your email address within
            5min</p>: <p>Thank you for your purchase. We will process your order and send the results to your email address within
            5min</p>}
          <Button size={"small"} color={"secondary"} onClick={() => {
            hide(`downloadSuccessModal`);
          }}>Okay</Button>
        </div>
      );
    }}
  </Modal>;
};


export default DownloadSuccessModal;
