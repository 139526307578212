import React, { useCallback, useEffect, useState } from "react";
import "./ApiReference.scss";
import Button from "../../components/core/Button";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../SignIn";
import { show } from "../../components/core/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiKey } from "../Pricing";
import scr1 from "../../assets/images/scr1.jpeg"
import scr2 from "../../assets/images/scr2.jpeg"

const ApiReference = () => {
  const [sidebarWidth, setSidebarWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const user = useSelector(userSelector)
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()
  useEffect(() => {
    const sidebarEl = document.querySelector('.sidebar').getBoundingClientRect();
    setSidebarWidth(sidebarEl.width);
    setSidebarTop(sidebarEl.top);
  }, []);
  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, [sidebarTop]);
  const isSticky = (e) => {
    const sidebarEl = document.querySelector('.sidebar-wrap');
    const scrollTop = window.scrollY;
    if (scrollTop >= 100) {
      sidebarEl.classList.add('is-sticky');
    } else {
      sidebarEl.classList.remove('is-sticky');
    }
  }

  const onGetApiKey = useCallback(() => {
    dispatch(getApiKey())
  }, [dispatch])

  return (
    <div className={"api-reference-page"}>
      <div className={"container"}>
        <h2 className={"mb-8 lg:mb-36"}>API Reference</h2>
        <div className={"row"}>
          <div className={"col-12 lg:col-3"}>
            <div className={'sidebar-wrap'}  style={{ width: sidebarWidth }}>
              <div className={"sidebar"}>
                <div className={"sidebar-item"}>
                  <HashLink smooth to={"#sec1"}>Introduction to XPASS API</HashLink>
                </div>
                <div className={"sidebar-item"}>
                  <HashLink smooth to={"#sec2"}>Get the XPASS API Key</HashLink>
                </div>
                <div className={"sidebar-item"}>
                  <HashLink smooth to={"#sec3"}>How to use XPASS API</HashLink>
                </div>
                <div className={"sidebar-item"}>
                  <HashLink smooth to={"#sec4"}>How to apply filters</HashLink>
                </div>
              </div>
              <Button onClick={() => {
                if (!user) {
                  navigate(`${location.pathname}?signIn`)
                  show("signInModal")
                } else {
                  onGetApiKey()
                }
              }} size={'small'} className={'mt-32'}>Get Your API Key</Button>
            </div>
          </div>
          <div className={"col-12 lg:col-9"}>
            <section className={"api-reference-section"} id={"sec1"}>
              <h3 className={"api-reference-section-title"}>Introduction to XPASS API</h3>
              <div className={"api-reference-section-body"}>
                <p>XPass provides an easy way to get real fingerprints and use them in automation purposes.
                  This XPass API documentation can be used as a reference to understand:</p>
                <ul>
                  <li>How to get access to the API</li>
                  <li>How to make calls using API and get response</li>
                  <li>How to apply filters and get data corresponding to it.</li>
                </ul>
                <p>If you have any questions that are not covered in this API documentation please write us an email
                  at <a href={"mailto:support@xpass.com"}>support@xpass.com</a></p>
              </div>
            </section>
            <section className={"api-reference-section"} id={"sec2"}>
              <h3 className={"api-reference-section-title"}>Get the XPASS API Key</h3>
              <div className={"api-reference-section-body"}>
                <p>API key can be accessed by purchasing Basic and Pro pricing plans. The main differences between the packages are number of calls to the
                  database.</p>
                <p>To obtain the API key follow these steps:</p>
                <ul>
                  <li>Register an account</li>
                  <li>Go to Pricing and obtain credits from Basic or Pro plan.</li>
                  <li>Go to Home Page and press “ Get Your API Key” action button.</li>
                  <li>Receive an email containing your API key.</li>
                  <li>Follow email instructions for opening API GUI.</li>
                </ul>
              </div>
            </section>
            <section className={"api-reference-section"} id={"sec3"}>
              <h3 className={"api-reference-section-title"}>How to use XPASS API</h3>
              <div className={"api-reference-section-body"}>
                {/*<p>API key can be accessed by purchasing Basic and Pro pricing plans. The main differences between the packages are number of calls to the*/}
                {/*  database.</p>*/}
                {/*<p>To obtain the API key follow these steps:</p>*/}
                <ul>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <li>Click on "Authorize" button</li>
                  <li>In the input field paste your API key, authorize and close the pop-up</li>
                  <li>Now you are ready to make API calls. Apply the filters and press “Execute” button.</li>
                </ul>
                <img src={scr1} alt={""}/>
              </div>
            </section>
            <section className={"api-reference-section"} id={"sec4"}>
              <h3 className={"api-reference-section-title"}>How to apply filters</h3>
              <div className={"api-reference-section-body"}>
                <p>How to apply filters
                  XPASS API provides an opportunity to apply filters before sending the request. Available filters are:
                  OS, Device type, Browser type, Resolution of Screen.<br/>
                  Just fill in the necessary filters and press execute button.</p>
                {/*<p>To obtain the API key follow these steps:</p>*/}
                {/*<ul>*/}
                {/*  <li>Register an account</li>*/}
                {/*  <li>Go to Pricing and obtain credits from Basic or Pro plan.</li>*/}
                {/*  <li>Go to Home Page and press “ Get Your API Key” action button.</li>*/}
                {/*  <li>Receive an email containing your API key.</li>*/}
                {/*</ul>*/}
                <img src={scr2} alt={""}/>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApiReference;
