import React from "react";
const PasswordSuccess = () => {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="104" height="104" rx="52" fill="url(#paint0_linear_145_11871)"/>
      <g filter="url(#filter0_i_145_11871)">
        <path fillRule="evenodd" clipRule="evenodd" d="M52 104C80.7188 104 104 80.7188 104 52C104 23.2812 80.7188 0 52 0C23.2812 0 0 23.2812 0 52C0 80.7188 23.2812 104 52 104ZM52 96C76.3005 96 96 76.3005 96 52C96 27.6995 76.3005 8 52 8C27.6995 8 8 27.6995 8 52C8 76.3005 27.6995 96 52 96Z" fill="url(#paint1_angular_145_11871)"/>
      </g>
      <path d="M52 30.875C45.7367 30.875 40.625 35.9867 40.625 42.25V45.5H37.375C35.5794 45.5 34.125 46.9544 34.125 48.75V68.25C34.125 70.0456 35.5794 71.5 37.375 71.5H66.625C68.4206 71.5 69.875 70.0456 69.875 68.25V48.75C69.875 46.9544 68.4206 45.5 66.625 45.5H63.375V42.25C63.375 36.1913 58.5594 31.3114 52.5776 30.9924C52.3939 30.9184 52.1981 30.8786 52 30.875ZM52 34.125C56.5072 34.125 60.125 37.7428 60.125 42.25V45.5H43.875V42.25C43.875 37.7428 47.4928 34.125 52 34.125ZM42.25 55.25C44.0456 55.25 45.5 56.7044 45.5 58.5C45.5 60.294 44.0456 61.75 42.25 61.75C40.4544 61.75 39 60.294 39 58.5C39 56.7044 40.4544 55.25 42.25 55.25ZM52 55.25C53.7956 55.25 55.25 56.7044 55.25 58.5C55.25 60.294 53.7956 61.75 52 61.75C50.2044 61.75 48.75 60.294 48.75 58.5C48.75 56.7044 50.2044 55.25 52 55.25ZM61.75 55.25C63.5456 55.25 65 56.7044 65 58.5C65 60.294 63.5456 61.75 61.75 61.75C59.9544 61.75 58.5 60.294 58.5 58.5C58.5 56.7044 59.9544 55.25 61.75 55.25Z" fill="url(#paint2_linear_145_11871)"/>
      <defs>
        <filter id="filter0_i_145_11871" x="0" y="0" width="104" height="106" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_145_11871"/>
        </filter>
        <linearGradient id="paint0_linear_145_11871" x1="52" y1="9" x2="52" y2="96" gradientUnits="userSpaceOnUse">
          <stop stopColor="#292A33"/>
          <stop offset="1" stopColor="#191A21"/>
        </linearGradient>
        <radialGradient id="paint1_angular_145_11871" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52 52) rotate(90) scale(46.5)">
          <stop stopColor="#13141A"/>
          <stop offset="0.498478" stopColor="#1E1F24"/>
          <stop offset="1" stopColor="#15161A"/>
        </radialGradient>
        <linearGradient id="paint2_linear_145_11871" x1="69.875" y1="30.875" x2="28.0963" y2="38.5096" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0B056"/>
          <stop offset="1" stopColor="#CD6A28"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default PasswordSuccess;
