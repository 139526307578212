import { call, put, takeEvery } from "@redux-saga/core/effects";
import { resendCodeAction, resendCodeError } from "./resendCodeSlice";
import { hide, show } from "../../../components/core/Modal";
import { toast } from "react-toastify";
import resendCodeService from "../services/resendCodeService";

function * resendCodeSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        resendCodeService.resendCode(payload.data),
    );
    if (response?.hasError) {
      yield put(resendCodeError());
      toast.error(response.errors[0])
    } else {
      payload.navigate(`${payload.location.pathname}?verify`);
      show("verifyEmailModal")
      hide("resendCodeModal")
      toast.success("Activation code has been successfully sent")
    }

  } catch (error) {
    yield put(resendCodeError());
  }
}

export function * watchFetchResendCode () {
  yield takeEvery(resendCodeAction.type, resendCodeSaga);
}

