import React, { useCallback } from "react";
import "./SignUpModal.scss";
import Modal, { hide, show } from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";
import CheckBox from "../../../../components/core/CheckBox";
import { useLocation, useNavigate } from "react-router-dom";

const SignUpModal = ({ onSubmit, register, errors, isLoading, isValid }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const showSignInModal = useCallback(() => {
    navigate(`${location.pathname}?signIn`)
    hide("signUpModal");
    show("signInModal");
  }, [location.pathname, navigate]);

  // const showSetPasswordModal = useCallback(() => {
  //   navigate(`${location.pathname}?setPassword=true`);
  //   hide("signUpModal");
  //   show("setPasswordModal");
  // }, [location.pathname, navigate]);

  return <Modal shouldCloseOnOverlayClick={false} id={"signUpModal"} title={"Create an Account"}>
    <form autoComplete="off">
      <Input
        error={errors.name?.message}
        register={register}
        placeholder="First name"
        name="name"
        id="fnameId"
      />
      <Input
        error={errors.lastName?.message}
        register={register}
        placeholder="Last name"
        name="lastName"
        id="lnameId"
      />
      <Input
        error={errors.email?.message}
        register={register}
        placeholder="Email"
        name="email"
        id="emailId"
      />
      {/*<CheckBox label={"I have read and accepted the Terms and Conditions & Privacy Policy"} className={"mt-24 lg:mt-36"} />*/}
      <div className={'px-16 lg:px-0'}>
        <Button
          loading={isLoading}
          disabled={isLoading || !isValid}
          // onClick={onSubmit}
          // type="submit"
          className={"w-full mt-24 lg:mt-32"}
          onClick={onSubmit}
        >
          Create Account
        </Button>
        <Button
          className={"w-full mt-20"}
          color={"secondary"}
          size={"small"}
          onClick={showSignInModal}
        >
          Already have an account? Sign in here
        </Button>
      </div>
    </form>
  </Modal>;
};

SignUpModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  isValid: PropTypes.any.isRequired
};

export default SignUpModal;
