import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: null,
    loader: false,
    homePageList: null,
    downloadHistory: null,
};

export const dataBaseSlice = createSlice({
    name: "dataBase",
    initialState,
    reducers: {
        getListAction: (state, action) => {
            state.loader = true;
        },
        getDownloadHistoryAction: (state, action) => {
            state.loader = true;
        },

        downloadHistoryListItem: (state, action) => {
            state.loader = true;
        },
        getDownloadHistoryActionSuccess: (state, action) => {
            state.downloadHistory = action.payload;
        },

        getHomePageList: (state, action) => {
            state.loader = true;
        },
        getHomePageListSuccess: (state, action) => {
            // state.loader = false;
            state.homePageList = action.payload;
        },

        getSeveralFingerPrints: (state, action) => {
            state.loader = true;
        },

        getSeveralCookiesFingerPrints: (state, action) => {
            state.loader = true;
        },

        getFilteredListAction: (state, action) => {
            state.loader = true;
        },

        getSingleFingerPrints: (state, action) => {
            state.loader = true;
        },

        getSingleCookiesFingerPrints: (state, action) => {
            state.loader = true;
        },


        getListActionSuccess: (state, action) => {
            state.list = action.payload
            state.loader = false
        },

        getListActionError: (state) => {
            state.list = null;
        },

        dataBaseActionError: (state) => {
            state.loader = false;
        },

        resetLoader: (state) => {
            state.loader = false;
        },

        resetState: (state) => {
            state.loader = false;
            state.list = null;
        },
    },
});

export const {
    getListAction,
    getListActionSuccess,
    getListActionError,
    getFilteredListAction,
    resetState,
    getSingleFingerPrints,
    getSingleCookiesFingerPrints,
    dataBaseActionError,
    getSeveralFingerPrints,
    getSeveralCookiesFingerPrints,
    getHomePageListSuccess,
    getHomePageList,
    getDownloadHistoryAction,
    getDownloadHistoryActionSuccess,
    downloadHistoryListItem,
    resetLoader,
} = dataBaseSlice.actions;
