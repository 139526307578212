import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Input.scss";
import InputFrame from "../InputFrame";

const Input = function Input(
  {
    onkeydown,
    error,
    disabled,
    className,
    type,
    multiline,
    placeholder,
    min,
    max,
    label,
    value,
    defaultValue,
    onChange,
    icon,
    id,
    name,
    register,
    ...rest
  },
) {
  return (
    <InputFrame error={error} className={className} disabled={disabled} icon={icon} id={id}>
      {({ inputClassName }) => (
        <>
          {label && type !== "hidden" && <label htmlFor={name}>{label || placeholder}</label>}

          {!multiline && (
            <input
              id={id}
              name={name}
              type={type}
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              className={inputClassName}
              maxLength={max || ""}
              max={max || ""}
              min={min || ""}
              {...register ? register(name) : null}
              onKeyDown={onkeydown}
              {...rest}
            />
          )}
          {multiline && (
            <textarea
              id={id}
              name={name}
              defaultValue={defaultValue}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              className={cx(inputClassName, 'textarea')}
              {...register ? register(name) : null}
              {...rest}
            />
          )}
        </>
      )}
    </InputFrame>
  );
};

Input.displayName = "Input";

Input.defaultProps = {
  id: undefined,
  name: undefined,
  defaultValue: undefined,
  onChange: undefined,
  placeholder: undefined,
  multiline: false,
  disabled: false,
  className: null,
  type: "text",
  value: undefined,
  error: false,
  label: null,
  icon: null,
  max: "",
  min: "",
  onkeydown: undefined,
};

Input.propTypes = {
  /** Input id */
  id: PropTypes.string,
  /** Input name */
  name: PropTypes.string,
  /** Change event callback */
  onChange: PropTypes.func,
  /** Input default value */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input type */
  type: PropTypes.string,
  /** Input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input placeHolder */
  placeholder: PropTypes.string,
  /** Add className to the component */
  className: PropTypes.string,
  /** Is input valid */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** If provided, will add label before the input */
  label: PropTypes.string,
  /** Has multi lines */
  multiline: PropTypes.bool,
  /** Disabled state of input */
  disabled: PropTypes.bool,
  /** Input icon. */
  icon: PropTypes.node,
  /** Input maxLength(mainly used by date-picker) */
  max: PropTypes.string,
  /** Input minLength(mainly used by date-picker) */
  min: PropTypes.string,
  /** Key down state */
  onkeydown: PropTypes.func,
  register: PropTypes.any,
};

export default Input;
