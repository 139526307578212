import React, { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./CheckBox.scss";

const CheckBox = forwardRef(function Input(
  {
    disabled,
    className,
    label,
    value,
    defaultChecked,
    onChange,
    id,
    name,
    indeterminate,
    register,
    ...rest
  },
  ref
) {
  const checked = defaultChecked || false;
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);
  const inputProps = {};
  if(defaultChecked) {
    inputProps.defaultChecked = true;
  }
  return (
    <div role="presentation" className={cx('checkBoxControl', className)}>
      <input
        type="checkbox"
        id={id || label}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        // ref={(input) => {
        //   inputRef.current = input;
        //   if (typeof ref === "function") {
        //     ref(input);
        //   } else if (ref) {
        //     ref.current = input;
        //   }
        // }}
        {...register ? register(name) : null}
        {...inputProps}
        {...rest}
      />
      <label htmlFor={id || label}>{label}</label>
    </div>
  );
});

CheckBox.defaultProps = {
  id: undefined,
  name: undefined,
  defaultValue: undefined,
  onChange: undefined,
  disabled: false,
  className: null,
  type: "checkbox",
  value: undefined,
  label: null,
  defaultChecked: false,
  indeterminate: false,
};

CheckBox.propTypes = {
  /** Input id */
  id: PropTypes.string,
  register: PropTypes.any,
  /** Input name */
  name: PropTypes.string,
  /** Change event callback */
  onChange: PropTypes.func,
  /** Input default value */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input type */
  type: PropTypes.string,
  /** Input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input placeHolder */
  className: PropTypes.string,
  /** If provided, will add label before the input */
  label: PropTypes.string,
  /** Disabled state of input */
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  /** Indeterminate state of input */
  indeterminate: PropTypes.bool,
};

export default CheckBox;
