import ServiceBase from "../../../services/apiServiceBase";

class ForgotService extends ServiceBase {
  async forgot(request) {
    return await this.post(`${this.apiUrl}/user/forgot-password`, request);
  }

}

export default new ForgotService();
