import qs from "qs";
import ServiceBase from "../../../services/apiServiceBase";

class DataBaseService extends ServiceBase {
  async getList() {
    return await this.get(`${this.apiUrl}/parser/?limit=120&offset=0`);
  }

  async getHomePageList(user) {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (user) {
      return await this.get(`${this.apiUrl}/parser/no-token/?token=${token}`);
    }
    return await this.get(`${this.apiUrl}/parser/no-token`);
  }

  async getHomePageListWithToken(token) {

    return await this.get(`${this.apiUrl}/parser/no-token/?token=${token}`);
  }
  async getFilterList(payload) {
    return await this.get(`${this.apiUrl}/parser/?${qs.stringify(payload)}`);
  }

  async getSingleFingerPrint(payload) {
    return await this.post(`${this.apiUrl}/parser/get-one`, payload);
  }

  async getSingleCookiesFingerPrints(payload) {
    return await this.post(`${this.apiUrl}/parser/get-one`, {...payload, onlyCookies:true});
  }
  async downloadHistoryListItem(payload) {
    return await this.get(`${this.apiUrl}/user/download-history/?${qs.stringify(payload)}`);
  }

  async getSeveralFingerPrints(payload) {
    return await this.post(`${this.apiUrl}/parser/download-bulk`, payload);
  }
  async getSeveralCookiesFingerPrints(payload) {
    return await this.post(`${this.apiUrl}/parser/download-bulk`, payload);
  }

  async getDownloadsHistory(payload) {
    return await this.get(`${this.apiUrl}/user/history/?${qs.stringify(payload)}`);
  }

}

export default new DataBaseService();
