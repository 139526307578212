import React from "react";
import "../Account.scss";
import Button from "../../../components/core/Button";
import { useSelector } from "react-redux";
import { userSelector } from "../../SignIn";
import { show } from "../../../components/core/Modal";

const Billing = () => {
  const user = useSelector(userSelector)
  return (
    <>
      <section className={'account-section'}>
        <h4 className={'account-section-title'}>My credits</h4>
        <div className={'account-section-body'}>
          <div className={'downloads row'}>
            <div className={'col-12 lg:col-3 downloads-item'}>
              <span className={'downloads-item-title'}>Total Credits Purchased</span>
              <span className={'downloads-item-count'}>{user?.purchased}</span>
            </div>
            <div className={'col-12 lg:col-3 downloads-item'}>
              <span className={'downloads-item-title'}>Credits Used</span>
              <span className={'downloads-item-count'}>{user?.alreadyDownloaded}</span>
            </div>
            <div className={'col-12 lg:col-3 downloads-item'}>
              <span className={'downloads-item-title'}>Credits Remaining</span>
              <span className={'downloads-item-count'}>{user?.balance}</span>
            </div>
            <div className={'col-12 lg:col-3 downloads-item text-right'}>
              <Button onClick={() => show('buyPackModal')} size={'small'} style={{fontSize: 13}}>Buy More Credits</Button>
            </div>
          </div>
        </div>
      </section>
      {/*<section className={'account-section'}>*/}
      {/*  <h4 className={'account-section-title'}>My API key</h4>*/}
      {/*  <div className={'account-section-body'}>*/}
      {/*    <div className={'flex flex-col lg:flex-row lg:space-x-24'}>*/}
      {/*      <Input value={'5165468-584641-85645411'} className={'input-light lg:mb-0'} readOnly />*/}
      {/*      <Button size={'small'} color={'secondary'} className={'copy-btn'}>Copy Key</Button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </>
  );
};
export default Billing;
