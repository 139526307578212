import React, { useEffect } from "react";
import VerifyEmailModal from "../components/VerifyEmailModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {useCallback} from "react";
import { verifyAction } from "../redux/verifySlice";
import { useLocation, useNavigate } from "react-router-dom";
import { hide, show } from "../../../components/core/Modal";
import { isNotActivatedUserSelector } from "../../SetPassword/redux/selectors";

const schema = yup
    .object({
        activation_code: yup.string().required("Activation code is required"),
    });

const VerifyEmailContainer = () => {
    const dispatch = useDispatch()
    // const isLoading = useSelector(isSignInLoader)
    const location = useLocation();
    const isNotActivatedUser = useSelector(isNotActivatedUserSelector)
    const navigate = useNavigate();
    const isLoading = false;
    const {register, handleSubmit,resetField,
        formState: {
            errors,
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        if (location?.search.includes("verify")) {
            show("verifyEmailModal")
            resetField("activation_code")
        }
    }, [location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(verifyAction({
            data,
            navigate,
            location
        }));
    },[dispatch, location, navigate]);

    const showResendCodeModal = useCallback(() => {
        hide("verifyEmailModal");
        show("resendCodeModal");
       navigate(`${location.pathname}?resendCode`);
    }, [location.pathname, navigate]);

    return <VerifyEmailModal showResendCodeModal={showResendCodeModal} isNotActivatedUser={isNotActivatedUser} isLoading={isLoading} errors={errors} register={register} onSubmit={handleSubmit(onSubmit)}/>
}

export default VerifyEmailContainer
