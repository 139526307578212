import React, { useEffect } from "react";
import ForgotPasswordModal from "../components/ForgotPasswordModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useCallback} from "react";
import { forgotAction } from "../redux/forgotSlice";
import { useLocation, useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    ).required("Email is required"),
});
const ForgotPasswordContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    // const isLoading = useSelector(isSignInLoader)
    const isLoading = false;
    const {register, handleSubmit,resetField,
        formState: {
            errors,
           isValid
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (location.search.includes("forgotPassword")) {
            resetField("email")
        }
    },[location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(forgotAction({
            data,
            navigate,
            location
        }));
    },[dispatch, location, navigate]);

    return <ForgotPasswordModal isValid={isValid} isLoading={isLoading} errors={errors} register={register} onSubmit={handleSubmit(onSubmit)}/>
}

export default ForgotPasswordContainer
