import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

export const resendCodeSlice = createSlice({
  name: "resendCode",
  initialState,
  reducers: {
    resendCodeAction: (state, action) => {
      state.loader = true;
    },

    resendCodeSuccess: (state) => {
      state.loader = false;
    },
    resendCodeError: (state) => {
      state.loader = false;
    },
  },
});

export const {
  resendCodeAction, resendCodeSuccess, resendCodeError
} = resendCodeSlice.actions;
