import React from "react";
const Lock = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2.25C9.10925 2.25 6.75 4.60925 6.75 7.5V9H5.25C4.42125 9 3.75 9.67125 3.75 10.5V19.5C3.75 20.3288 4.42125 21 5.25 21H18.75C19.5787 21 20.25 20.3288 20.25 19.5V10.5C20.25 9.67125 19.5787 9 18.75 9H17.25V7.5C17.25 4.70369 15.0274 2.45142 12.2666 2.3042C12.1818 2.27003 12.0914 2.25166 12 2.25ZM12 3.75C14.0802 3.75 15.75 5.41975 15.75 7.5V9H8.25V7.5C8.25 5.41975 9.91975 3.75 12 3.75ZM7.5 13.5C8.32875 13.5 9 14.1713 9 15C9 15.828 8.32875 16.5 7.5 16.5C6.67125 16.5 6 15.828 6 15C6 14.1713 6.67125 13.5 7.5 13.5ZM12 13.5C12.8287 13.5 13.5 14.1713 13.5 15C13.5 15.828 12.8287 16.5 12 16.5C11.1712 16.5 10.5 15.828 10.5 15C10.5 14.1713 11.1712 13.5 12 13.5ZM16.5 13.5C17.3287 13.5 18 14.1713 18 15C18 15.828 17.3287 16.5 16.5 16.5C15.6713 16.5 15 15.828 15 15C15 14.1713 15.6713 13.5 16.5 13.5Z"
        fill="#64627A" />
    </svg>
  )
}
export default Lock;
