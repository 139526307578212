import React, { useEffect } from "react";
import ResetPasswordModal from "../components/ResetPasswordModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useCallback} from "react";
import { resetAction } from "../redux/resetSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { show } from "../../../components/core/Modal";

const schema = yup
    .object({
        password: yup
          .string()
          .required("")
          .min(8, "")
          .matches(/(?=.*\d)/, "")
          .matches(/(?=.*[A-Z])/, "")
          .matches(/(?=.*\W+)/, "")
          .matches(/(?=.*[a-z])/, ""),
    });

const ResetPasswordContainer = () => {
    const dispatch = useDispatch()
    // const isLoading = useSelector(isSignInLoader)
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");
    const isLoading = false;
    const {register, handleSubmit, watch, resetField,
        formState: {
            isValid,
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            password: ""
        }
    });

    const passwordWatch = watch("password")


    useEffect(() => {
        if (location.search.includes("reset-password")) {
            resetField("password")
            show("resetPasswordModal")
        }

    },[location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(resetAction({
            data,
            token,
            navigate,
            location
        }));
    },[dispatch, location, navigate, token]);

    return <ResetPasswordModal isValid={isValid}
                               passwordWatch={passwordWatch}
                               isLoading={isLoading}
                               register={register}
                               onSubmit={handleSubmit(onSubmit)}/>
}

export default ResetPasswordContainer
