import ServiceBase from "../../../services/apiServiceBase";

class VerifyService extends ServiceBase {
  async register(request) {
    return await this.post(`${this.apiUrl}/user/activate`, request);
  }

}

export default new VerifyService();
