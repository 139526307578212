import React from "react";
import "./Pricing.scss";
import img from "../../assets/images/notfound.png";
import fingerprintBg from "../../assets/images/fingerprint-left.svg";
import Pack from "../../components/Pack/Pack";
import btcpay from "../../assets/images/btcpay.svg";
import { BASIC_OPTIONS, PRO_OPTIONS } from "../../constants/constants";

const Pricing = () => {
  return (
    <div className={'pricing-page'}>
      <div className={'design-elements'}>
        <img src={fingerprintBg} alt={''} />
      </div>
      <div className={'container'}>
        <h1 className={'mb-32 lg:mb-64'}>Pricing</h1>
        <div className={'row'}>
          {/*<div className={'col-12 lg:col-4'}>*/}
          {/*  <Pack color={'free'} packType={'static'} defaultCount={10} activeFeatures={[0]} />*/}
          {/*</div>*/}
          <div className={'col-12 lg:col-4'}>
            <Pack color={'basic'} packType={'selectbox'} activeFeatures={[0,1]}  defaultCount={30} options={BASIC_OPTIONS}/>
          </div>
          <div className={'col-12 lg:col-4'}>
            <Pack color={'pro'} packType={'selectbox'} activeFeatures={[0,1]} defaultCount={5000} options={PRO_OPTIONS}/>
          </div>
        </div>
        <div className={"btcpay-info hidden lg:flex items-center justify-between mt-70"}>
          <p>All payments are accepted only in Bitcoin (BTC) and are processed by BTCPAY</p>
          <img src={btcpay} alt={""} />
        </div>
      </div>
    </div>
  );
};
export default Pricing;
