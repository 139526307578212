import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./InputFrame.scss";

const InputFrame = ({ error, disabled, className, children, icon, type, id }) => {
  return (
    <div className={cx(className, 'form-group', { ['form-group-hidden']: type === "hidden" })}>
      {icon && id !== "searchInputId" && <span className={'fieldIcon'}>{icon}</span>}
      {children({
        inputClassName: cx('form-control', {
          ['inputControl']: type === "input",
          ['selectControl']: type === "select",
          ['notValid']: error,
          ['disabledInput']: disabled,
          ['withIcon']: icon,
        }),
      })}
      {error && (
        <div className={cx('inputError')} id={`${id}Error`}>
          {typeof error === "string" ? error : null}
        </div>
      )}
    </div>
  );
};

InputFrame.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  icon: PropTypes.node,
  id: PropTypes.string,
};

InputFrame.defaultProps = {
  disabled: false,
  error: false,
  className: null,
  type: "input",
  /** Input icon. */
  icon: null,
  id: "",
};

export default InputFrame;
