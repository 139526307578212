import React, { useCallback } from "react";
import fingerprintBg from "../../assets/images/fingerprint.svg";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import telegramIcon from "../../assets/images/icons/telegram.svg";
import Input from "../../components/core/Input";
import Button from "../../components/core/Button";
import { sendEmailAction } from "../SignIn";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Please enter a valid email address"
  ).required("Email is required"),
  message: yup.string().required("Message is required")
});
const Footer = () => {
  const dispatch = useDispatch()
  const {register, handleSubmit,resetField,
    formState: {
      errors
    }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      message: "",
    }
  });
  const onSubmitEmail = useCallback((data) => {
    dispatch(sendEmailAction(data))
    resetField("email")
    resetField("message")
  }, [dispatch, resetField])

  return <footer id={"footer"}>
    <div className={"design-element"}>
      <img src={fingerprintBg} alt={""} />
    </div>
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-12 lg:col-3 flex flex-col"}>
          <Link to="/" className="flex items-center navbar-brand">
            <img src={logo} alt={"XPASS"} className={"h-[32px]"} />
          </Link>
          <div className={"footer-contact-block"}>
            <div className={"email"}>
              <p className={"email-title"}>Email:</p>
              <a className={"email-link"} href={"mailto:support@xpass.pro"}>support@xpass.pro</a>
            </div>
            <div className={"telegram"}>
              <img src={telegramIcon} alt={""} />
              <p>Got Feedback? <a target={"_blank"} href={"https://t.me/+wexvM_XLMlk1N2U8"} rel="noreferrer">join our telegram channel!</a></p>
            </div>
          </div>
          <p className={"footer-copy hidden lg:block"}>(c)2023 XPASS. All rights reserved</p>
        </div>
        <div className={"col-12 lg:col-6 lg:push-3"}>
          <div className={"footer-form"}>
            <h3 className={"mb-24"}>Have a question? <br className={"block lg:hidden"} /> <span
              className={"text-light"}>Drop us a line!</span></h3>
            <form autoComplete="off">
              <Input  error={errors.email?.message}
                      register={register}
                      name={"email"}
                      placeholder={"Enter your Email"}
                      className={"input-light"} />
              <Input error={errors.message?.message}
                     register={register}
                     name={"message"}
                     placeholder={"Enter your message"}
                     multiline
                     className={"input-light"} />
              <Button  onClick={handleSubmit(onSubmitEmail)} color={"primary"} size={"small"}>Send Message</Button>
            </form>
          </div>
          <p className={"footer-copy block lg:hidden"}>(c)2023 XPASS. All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer
