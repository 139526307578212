import ServiceBase from "../../../services/apiServiceBase";

class ResendCodeService extends ServiceBase {
  async resendCode(request) {
    return await this.post(`${this.apiUrl}/user/resend-activation`, request);
  }

}

export default new ResendCodeService();
