import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import "./Account.scss";
import Billing from "./Billing/Billing";
import Information from "./Information/Information";
import Password from "./Password/Password";
import fingerPrintBg from '../../assets/images/fingerprint-left.svg';
import Bil from "../../components/core/Icons/Bil";
import Lock from "../../components/core/Icons/Lock";
import Avatar from "../../components/core/Icons/Avatar";
import HistoryIcon from "../../components/core/Icons/History";
import { useSelector } from "react-redux";
import { userSelector } from "../SignIn";
import History from "./History/History";

const Account = () => {
  const user = useSelector(userSelector)

  return (
    <>
      <div className={"my-account"}>
        <div className={'design-elements'}>
          <img className={''} src={fingerPrintBg} alt={''}/>
          <div className={'elipse'}></div>
        </div>
        <div className={"container"}>
          <h2 className={"mb-32"}>My Account</h2>
          <div className={"row"}>
            <div className={"col-12 lg:col-4 xl:col-3"}>
              <div className={"sidebar"}>
                <div className={"sidebar-item"}>
                  <NavLink to={"/account/"}>
                    <Bil />
                    <span>Billing</span>
                  </NavLink>
                </div>
                <div className={"sidebar-item"}>
                  <NavLink to={"/account/information"}>
                    <Avatar />
                    <span>Personal information</span>
                    {/*<span className={'notification'}>1</span>*/}
                  </NavLink>
                </div>
                <div className={"sidebar-item"}>
                  <NavLink to={"/account/history"}>
                    <HistoryIcon />
                    <span>Downloads History</span>
                    {/*<span className={'notification'}>1</span>*/}
                  </NavLink>
                </div>
                <div className={"sidebar-item"}>
                  <NavLink to={"/account/password"}>
                    <Lock />
                    <span>Change password</span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={"col-12 lg:col-8 xl:push-1"}>
              <Routes>
                <Route index element={<Billing />} />
                <Route path="/history" element={<History />} />
                {user && <Route path="/information" element={<Information />} />}
                <Route path="/password" element={<Password />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
