import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./rootSaga";
import { signInSlice } from "../modules/SignIn";
import { registerSlice } from "../modules/SetPassword";
import { verifySlice } from "../modules/VerifyEmail";
import { forgotSlice } from "../modules/ForgotPassword";
import { resetSlice } from "../modules/ResetPassword";
import { accountSlice } from "../modules/Account";
import { dataBaseSlice } from "../modules/Database";
import { pricingSlice } from "../modules/Pricing";
import { resendCodeSlice } from "../modules/ResendCode";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        [signInSlice.name]: signInSlice.reducer,
        [registerSlice.name]: registerSlice.reducer,
        [verifySlice.name]: verifySlice.reducer,
        [forgotSlice.name]: forgotSlice.reducer,
        [resetSlice.name]: resetSlice.reducer,
        [accountSlice.name]: accountSlice.reducer,
        [dataBaseSlice.name]: dataBaseSlice.reducer,
        [pricingSlice.name]: pricingSlice.reducer,
        [resendCodeSlice.name]: resendCodeSlice.reducer,
    },
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

