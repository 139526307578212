import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

export const pricingSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    createInvoiceAction: (state, action) => {
      state.loader = true;
    },

    addInvoice: (state, action) => {
      state.loader = false;
    },


    getFree: (state, action) => {
      state.loader = false;
    },

    getApiKey: (state, action) => {
      state.loader = false;
    },

    createInvoiceSuccess: (state, action) => {
      state.loader = false;
    },
    createInvoiceError: (state) => {
      state.loader = false;
    },
  },
});

export const {
  createInvoiceAction,
  createInvoiceSuccess,
  createInvoiceError,
  addInvoice,
  getFree,
  getApiKey
} = pricingSlice.actions;
