import React, { lazy, Suspense } from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./components/MainLayout/MainLayout";
import Home from "./modules/Home";
import NotFound from "./components/NotFound/NotFound";
import Pricing from "./modules/Pricing";
import ApiReference from "./modules/ApiReference";
// import Database from "./modules/Database";
import { Helmet } from "react-helmet";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { getUserLoaderSelector, userSelector } from "./modules/SignIn";
const Database = lazy(() => import("./modules/Database"));
const Account = lazy(() => import("./modules/Account"));

const App = () => {
  const user = useSelector(userSelector)
  const isLoading = useSelector(getUserLoaderSelector)

  return (
      <>
        <Helmet>
          <title>XPASS</title>
          <meta name="XPASS" content="XPASS" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
        <BrowserRouter>
          {isLoading &&
            <div className="loader-container">
            <div className="spinner" />
          </div>}

          <Routes>
            <Route element={<MainLayout />}>
              <Route index element={<Home />} />
              {user && <>
                <Route path="/database" element={ <Suspense fallback={<div className="loader-container">
                  <div className="spinner" /></div>}>
                  <Database />
                </Suspense>} />
              <Route path="/account/*" element={<Suspense fallback={<div className="loader-container">
                <div className="spinner" /></div>}>
                <Account />
              </Suspense>} />
              </>}
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/api-reference" element={<ApiReference />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
  );
}

export default App;
