import React from "react";
import "./ForgotPasswordModal.scss";
import Modal from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";

const ForgotPasswordModal = ({ onSubmit, register, errors, isLoading, isValid }) => {
  return <Modal shouldCloseOnOverlayClick={false} id={"forgotPasswordModal"} title={"Forgot Password"}>
    <form autoComplete="off">
      <p className={'c1 text-light text-secondary mb-15'}>Enter your account email to reset your password</p>
      <Input
        error={errors.email?.message}
        register={register}
        placeholder="Enter your email"
        name="email"
        id="emailId"
      />
      <div className={'px-16 lg:px-0'}>
        <Button
          loading={isLoading}
          disabled={isLoading || !isValid}
          onClick={onSubmit}
          type="submit"
          className={"w-full mt-16"}
        >
          Reset password
        </Button>
      </div>
    </form>
  </Modal>;
};

ForgotPasswordModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  isValid: PropTypes.any.isRequired
};

export default ForgotPasswordModal;
