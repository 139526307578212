import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Database.scss";
import PC from "../../components/core/Icons/PC";
import Windows from "../../components/core/Icons/Windows";
import Edge from "../../components/core/Icons/Edge";
import Download from "../../components/core/Icons/Download";
import DownloadCookies from "../../components/core/Icons/DownloadCookies";
import Phone from "../../components/core/Icons/Phone";
import Android from "../../components/core/Icons/Android";
import Chrome from "../../components/core/Icons/Chrome";
import Mac from "../../components/core/Icons/Mac";
import Firefox from "../../components/core/Icons/Firefox";
import Safari from "../../components/core/Icons/Safari";
import Button from "../../components/core/Button";
import Filter from "../../components/core/Icons/Filter";
import Select from "react-dropdown-select";
import Close from "../../components/core/Icons/Close";
import CheckBox from "../../components/core/CheckBox";
import {
  getFilteredListAction,
  getListAction, getSeveralCookiesFingerPrints,
  getSeveralFingerPrints, getSingleCookiesFingerPrints,
  getSingleFingerPrints
} from "./redux/dataBaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { isDataBaseLoader, listSelector } from "./redux/selectors";
import {
  BROWSER,
  COOKIE_AGE,
  COOKIE_OPTIONS,
  COUNTRY,
  DEVICE_TYPE, INTEREST,
  OS,
  SCREEN_RESOLUTION
} from "../../constants/constants";

const Database = () => {
  const ref = useRef();
  const list = useSelector(listSelector)
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [someRowsSelected, setSomeRowsSelected] = useState(false);
  const [selected, setSelected] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 15;
  const totalPages = Math.ceil(list?.length / ITEMS_PER_PAGE);
  const dispatch = useDispatch()
  const isLoading = useSelector(isDataBaseLoader)
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (filterDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setFilterDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [filterDropdownOpen]);

  const [temporarySelected, setTemporarySelected] = useState({
    'deviceType': {label: "Select Device type"},
    'os': {label: "Select OS"},
    'browser': {label: 'Select Browser'},
    'resolution': {label: "Select Screen resolution"},
    'cookie': {label: "Mix", value: "mix"},
    'cookieDate': {label: "Select Cookie age"},
    'country': {label: "Select Country"},
    'interest': [],
  });
  useEffect(() => {
    setFiltered(false);
    for (const v of Object.values(selected)) {
      if(v.label) {
        setFiltered(true);
      }
    }
  }, [selected]);

  useEffect(() => {
    dispatch(getListAction())
  }, [dispatch])

  const setTemporarySelectedHandler = (values, filterName) => {
    if (filterName === "interest") {
      setTemporarySelected({
        ...temporarySelected,
        [filterName]: values.map((el) => el.value),
      });
    } else {
      setTemporarySelected({
        ...temporarySelected,
        [filterName]: values[0],
      });
    }

  }
  const handleSubmit = () => {
   const requestData = {
     limit:120,
     offset: 0,
     os: temporarySelected?.os?.value && temporarySelected?.os?.value,
     browser: temporarySelected?.browser?.value && temporarySelected?.browser?.value,
     device_type:temporarySelected?.deviceType?.value && temporarySelected?.deviceType.value ,
     resolution: temporarySelected?.resolution?.value && temporarySelected?.resolution?.value,
    }
    dispatch(getFilteredListAction(requestData))
    setSelected(temporarySelected);
    setFilterDropdownOpen(false);
  }

  const handleReset = () => {
    setSelected({});
    setTemporarySelected({
      'deviceType': {label: "Select Device type"},
      'os': {label: "Select OS"},
      'browser': {label: 'Select Browser'},
      'resolution': {label: "Select Screen resolution"},
      'cookie': {label: "Mix", value: "mix"},
      'cookieDate': {label: "Select Cookie age"},
      'country': {label: "Select Country"},
      'interest': [],
    });
    dispatch(getListAction())
  }

  const handleRemoveFilter = (filterName) => {
    let label
    let requestData = {
      limit:120,
      offset: 0,
      os: temporarySelected?.os?.value && temporarySelected?.os?.value,
      browser: temporarySelected?.browser?.value && temporarySelected?.browser?.value,
      device_type:temporarySelected?.deviceType?.value && temporarySelected?.deviceType.value ,
      resolution: temporarySelected?.resolution?.value && temporarySelected?.resolution?.value,
      cookieDate: temporarySelected?.cookieDate?.value && temporarySelected?.cookieDate?.value,
    }
    switch (filterName) {
      case "deviceType" :
         label = "Select Device type"
        delete requestData["device_type"]
         break
      case "os" :
         label = "Select OS"
        delete requestData["os"]
          break
      case "browser" :
         label = "Select Browser"
        delete requestData["browser"]
          break
      case "resolution" :
        delete requestData["resolution"]
         label = "Select Screen resolution"
          break
      case "cookieDate" :
        delete requestData["cookieDate"]
         label = "Select Cookie age"
          break
      case "country" :
        delete requestData["country"]
         label = "Select Country"
          break
      default: return
    }
    setTemporarySelected({
      ...temporarySelected,
      [filterName]: {label: label}
    })
    setSelected({
      ...temporarySelected,
      [filterName]: {label: label},
    });
    dispatch(getFilteredListAction(requestData))

  }

  const handleSelectRow = (event, id) => {
    const isSelected = event.target.checked;
    if (isSelected) {
      setSelectedRows([...selectedRows, id])
    } else {
      const newRows = selectedRows.filter((el) => el !== id)
      setSelectedRows(newRows)
    }
  };
  const handleSelectAllRows = (event) => {
    const isSelected = event.target.checked;
    setAllRowsSelected(isSelected);
    if (isSelected) {
      setSelectedRows(list.map((l) => l.id));
    } else {
      setSelectedRows([]);
    }
  };
  const handleUnSelectAllRows = () => {
    setAllRowsSelected(false);
    setSomeRowsSelected(false);
    setSelectedRows([]);
  };

  const handleOsIcon = useCallback((data) => {
    switch (data?.os) {
      case "iOS":
        return <Mac />;
      case "Windows":
        return <Windows/>;
      case "Android":
        return <Android />;
      case "Linux":
        return <Windows />;
      default:
        return <Mac />;
    }

  }, [])

  const getSeveralData = useCallback(() => {
    dispatch(getSeveralFingerPrints({interest: temporarySelected?.interest || [],country:temporarySelected?.country?.value ,cookieDate: temporarySelected?.cookieDate?.value &&  {month: temporarySelected?.cookieDate?.value, year: temporarySelected?.cookieDate > 4 ? 2023 : 2024  }, ids: selectedRows, cookie: temporarySelected?.cookie?.value}))
    setSelectedRows([]);

  }, [dispatch, selectedRows, temporarySelected?.cookie?.value, temporarySelected?.cookieDate?.value, temporarySelected?.country?.value, temporarySelected?.interest])

  const getSeveralCookiesData = useCallback(() => {
    dispatch(getSeveralCookiesFingerPrints({interest: temporarySelected?.interest || [],country:temporarySelected?.country?.value , onlyCookies: true, cookieDate: temporarySelected?.cookieDate?.value &&  {month: temporarySelected?.cookieDate?.value, year: temporarySelected?.cookieDate > 4 ? 2023 : 2024  }, ids: selectedRows, cookie: temporarySelected?.cookie?.value}))
    setSelectedRows([]);

  }, [dispatch, selectedRows, temporarySelected?.cookie?.value, temporarySelected?.cookieDate?.value, temporarySelected?.country?.value, temporarySelected?.interest])

  const handleBrowserIcon = useCallback((data) => {
    if (data?.browser.includes("Safari")) {
      return  <Safari />
    }
    if (data?.browser.includes("Chrome")) {
      return  <Chrome />
    }
    if (data?.browser.includes("Edge")) {
      return  <Edge />
    }
    if (data?.browser.includes("Firefox")) {
      return <Firefox />
    } else {
      return  <Chrome />
    }

  }, [])

  const handleCookie = useCallback((data) => {
    switch (data) {
      case "mix":
        return "Mix";
      case "google":
        return "Google";
      case "facebook":
        return "Facebook";
        case "bing":
        return "Bing";
      case "ebay":
        return "eBay";
      case "etsy":
        return "Etsy";
      case "amazon":
        return "Amazon";
      default:
        return "Mix";
    }

  }, [])


  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getPageData = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return list.slice(startIndex, endIndex);
  };

  return (
    <>
      {isLoading &&
        <div className="loaderDataBaseContainer">
      <div className="loader"></div>
      <p>Loading ... </p>
    </div>
      }
    <div className={"api-reference-page"}>
      <div className={"container"}>
        <div className={'flex justify-between items-center space-x-32'}>
          <h2 className={"mb-8 lg:mb-36"}>Fingerprint Database</h2>
          <div className={`dropdown-filter`} ref={ref}>
            <button className={`filter-btn  ${filterDropdownOpen && "active"}  ${filtered && "filtered"}`} onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}><Filter /> Filters</button>
            <div className={`dropdown-filter-block ${filterDropdownOpen && "active"}`}>
              <p className={'dropdown-filter-block-title'}>Apply Filters</p>
              <div className={'dropdown-filter-block-content'}>
                <div className={'row'}>
                  <div className={'col-12 lg:col-6'}>
                    <label>Device type</label>
                    <Select searchable={false} multi={false}
                            values={[temporarySelected.deviceType]}
                            placeholder={'Select Device type'}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'deviceType')}
                            options={DEVICE_TYPE} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>OS</label>
                    <Select searchable={false} multi={false}
                            placeholder={'Select OS'}
                            values={[temporarySelected.os]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'os')}
                            options={OS} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>Browser</label>
                    <Select searchable={false} multi={false}
                            placeholder={'Select Browser'}
                            closeOnScroll={true}
                            values={[temporarySelected.browser]}
                            onChange={(values) => setTemporarySelectedHandler(values, 'browser')}
                            options={BROWSER} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>Screen resolution</label>
                    <Select searchable={false} multi={false}
                            placeholder={'Select Screen resolution'}
                            values={[temporarySelected.resolution]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'resolution')}
                            options={SCREEN_RESOLUTION} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>WEBSITE</label>
                    <Select searchable={false}
                            multi={false}
                            placeholder={'Select Cookie'}
                            values={[temporarySelected.cookie]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'cookie')}
                            options={COOKIE_OPTIONS} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>Cookie age</label>
                    <Select searchable={false}
                            multi={false}
                            placeholder={'Select Cookie age'}
                            values={[temporarySelected.cookieDate]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'cookieDate')}
                            options={COOKIE_AGE} />
                  </div>
                  <div className={'col-12 lg:col-6'}>
                    <label>Country</label>
                    <Select searchable={false}
                            multi={false}
                            placeholder={'Select Country'}
                            values={[temporarySelected.country]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'country')}
                            options={COUNTRY} />
                  </div>

                  <div className={'col-12 lg:col-6'}>
                    <label>Interest</label>
                    <Select searchable={true}
                            multi={true}
                            placeholder={'Select Interest'}
                            // values={[temporarySelected?.interest]}
                            closeOnScroll={true}
                            onChange={(values) => setTemporarySelectedHandler(values, 'interest')}
                            options={INTEREST} />
                  </div>
                </div>
                <div className={'flex space-x-16 justify-end'}>
                  <Button size={'small'} color={'secondary'} onClick={handleReset}>Reset</Button>
                  <Button size={'small'} color={'primary'} onClick={handleSubmit}>Apply Filters</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'selected-filters'}>
          {Object.keys(selected).map((key, index) => {
            const value = selected[key];
            return (
              <React.Fragment key={index}>
                {value.value && <div className={'selected-filter'}>
                  <span>{value.label}</span>
                  {value.label !== "eBay" &&
                    value.label !== "Amazon" &&
                    value.label !== "Etsy" &&
                    value.label !== "Google" &&
                    value.label !== "Bing" &&
                    value.label !== "Mix" &&
                    value.label !== "Facebook"  &&
                    <button onClick={() => handleRemoveFilter(key)}><Close /></button>}
                </div>}
              </React.Fragment>
            );
          })}
        </div>
        <div className={'responsive-table'}>
          <table>
            <thead>
            <tr>
              <th className={'check-td'}>
                <CheckBox checked={allRowsSelected}
                          onChange={handleSelectAllRows}
                          indeterminate={someRowsSelected}
                />
              </th>
              <th>Device type</th>
              <th>OS</th>
              <th>Browser</th>
              <th>Screen Resolution</th>
              <th>WebGL</th>
              <th>Cookie</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {list && getPageData().map((fingerPrint, index) => {
              return <tr key={index}>
                <td className={'check-td'}>
                  <CheckBox
                    checked={selectedRows.find((el) => el === fingerPrint.id)}
                            onChange={(e) => handleSelectRow(e, fingerPrint.id)} />
                </td>
                <td>{fingerPrint?.device_type === "mobile" ? <Phone/>:<PC/>} {fingerPrint?.device_type}</td>
                <td> {handleOsIcon(fingerPrint)} {fingerPrint?.os}</td>
                <td>{handleBrowserIcon(fingerPrint)} {fingerPrint?.browser}</td>
                <td>{fingerPrint?.resolution}</td>
                <td className={"webgl"}>{fingerPrint?.webgl}</td>
                <td className={"webgl"}>{handleCookie(temporarySelected?.cookie?.value)}</td>

                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/mouse-events-have-key-events */}
                <td className={"download_icon_cookies"} onClick={() => dispatch(getSingleCookiesFingerPrints({interest: temporarySelected?.interest || [],country:temporarySelected?.country?.value , month: temporarySelected?.cookieDate?.value && temporarySelected?.cookieDate?.value, year: temporarySelected?.cookieDate?.value && temporarySelected?.cookieDate?.value> 4 ? 2023 : 2024, id: fingerPrint.id, cookie: temporarySelected?.cookie.value }))}><DownloadCookies /></td>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <td className={"download_icon_profile"} onClick={() => dispatch(getSingleFingerPrints({interest: temporarySelected?.interest || [],country:temporarySelected?.country?.value , month: temporarySelected?.cookieDate?.value && temporarySelected?.cookieDate?.value, year: temporarySelected?.cookieDate?.value && temporarySelected?.cookieDate?.value> 4 ? 2023 : 2024, id: fingerPrint.id, cookie: temporarySelected?.cookie.value }))}><Download /></td>
              </tr>
            })}
            </tbody>
          </table>
        </div>
        {totalPages === page && <p className={"infoText"}>Not all results are present in the table. If you want to access the full database, please use our API.
        </p>}

        <nav className={'pagination mt-32'}>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber, index) => (
            <button key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
               <p className={page === index+1 && 'active'}>{pageNumber}</p>
            </button>
          ))}
        </nav>
      </div>

      {selectedRows.length  > 1 && <div className={'status-bar-download'}>
        <div className={'container'}>
          <div className={'status-bar-download-wrap'}>
            <button className={'download-cancel'} onClick={handleUnSelectAllRows}><Close /> Cancel</button>
            <div className={'download-formats'}>
              {/*<p>Download selected as:</p>*/}
              <Button size={"small"} color={'primary'}

                // onClick={() => show('successModal-download')}
                      onClick={getSeveralCookiesData}
              >Download Cookies</Button>
              <Button size={"small"} color={'primary'}

                      // onClick={() => show('successModal-download')}
                      onClick={getSeveralData}
              >Download Profiles</Button>
              {/*<Button size={"small"} color={'secondary'} onClick={() => show('successModal-email')}>XML</Button>*/}
              {/*<Button size={"small"} color={'secondary'} onClick={() => show('successModal-password')}>CSV</Button>*/}
              {/*<Button size={"small"} color={'secondary'} onClick={() => show('successModal-download')}>Excel</Button>*/}
            </div>
            {/*<div className={'download-formats'}>
              <Button size={"small"} color={'primary'} onClick={() => show('successModal')}>Download Selected</Button>
            </div>*/}
          </div>
        </div>
      </div> }
    </div>
    </>
  );
};
export default Database;
