import { call, put, takeEvery } from "@redux-saga/core/effects";
import { addInvoice, createInvoiceAction, createInvoiceError, getApiKey, getFree } from "./pricingSlice";
import { toast } from "react-toastify";
import pricingService from "../services/pricingService";
import { getUserAction } from "../../SignIn";
import { show } from "../../../components/core/Modal";

function * createInvoiceSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        pricingService.createInvoice(payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(createInvoiceError());
    } else {
      window.location.href = response.payload.checkoutLink
      // payload.navigate(`${payload.location.pathname}?success`);
      // hide("forgotPasswordModal")
      // show("successModalEmail")

    }

  } catch (error) {
    yield put(createInvoiceError());
  }
}

function * addInvoiceSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        pricingService.addInvoice(payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(createInvoiceError());
    } else {
      yield put(getUserAction())
      window.location.href = "/"

    }

  } catch (error) {
    yield put(createInvoiceError());
  }
}

function * getFreeSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        pricingService.getFree(),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(createInvoiceError());
    } else {
      yield put(getUserAction())
    }

  } catch (error) {
    yield put(createInvoiceError());
  }
}

function * getApiKeySaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        pricingService.getApiKey(),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(createInvoiceError());
    } else {
      show("getApiKeySuccessModal")
    }

  } catch (error) {
    yield put(createInvoiceError());
  }
}

export function * watchFetchPrice () {
  yield takeEvery(createInvoiceAction.type, createInvoiceSaga);
  yield takeEvery(addInvoice.type, addInvoiceSaga);
  yield takeEvery(getFree.type, getFreeSaga);
  yield takeEvery(getApiKey.type, getApiKeySaga);
}

