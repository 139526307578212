import React, { useCallback } from "react";
import "./VerifyEmailModal.scss";
import Modal, { hide, show } from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";

const VerifyEmailModal = ({ onSubmit, register, errors, isLoading, isNotActivatedUser, showResendCodeModal }) => {

  return <Modal shouldCloseOnOverlayClick={false} id={"verifyEmailModal"} title={"Verify Email"}>
    <form autoComplete="off" className={'verify-email-form'}>
      <p className={'c1 text-light text-secondary'}>Please enter the 6-digit verification code sent to</p>
      <p className={'c1 text-light text-primary mb-15'}>{isNotActivatedUser?.email || "Your email"} </p>
      <Input
        error={errors.activation_code?.message}
        register={register}
        placeholder="Enter the 6-digit code"
        name="activation_code"
        id="activation_codeId"
      />
      <p className={'mt-32 c1 text-light text-secondary'}>
        Didn’t receive a code? <span> </span>
        <Button
          onClick={showResendCodeModal}
          color={'link'}
        >
          Resend the code
        </Button>
      </p>
      {/*<p className={'mt-12 c1 text-light text-secondary'}>*/}
      {/*  Use different Email? <span> </span>*/}
      {/*  <Button*/}
      {/*    color={'link'}*/}
      {/*    onClick={showChangeEmailModal}*/}
      {/*  >*/}
      {/*    Change the email*/}
      {/*  </Button>*/}
      {/*</p>*/}
      <div className={'px-16 lg:px-0'}>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
          type="submit"
          className={"w-full mt-32"}
        >
          Verify Email
        </Button>
      </div>
    </form>
  </Modal>;
};

VerifyEmailModal.defaultProps = {
  isNotActivatedUser: null,
};

VerifyEmailModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  showResendCodeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  isNotActivatedUser: PropTypes.object
};

export default VerifyEmailModal;
