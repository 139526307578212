import React from "react";
const Bil = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.5C11.3363 1.5 10.7463 1.793 10.3345 2.25H8.25146C7.72121 2.25 7.35695 2.78632 7.5542 3.27832L8.25 5.25H15.75L16.4458 3.27832C16.6431 2.78632 16.2788 2.25 15.7485 2.25H13.6655C13.2537 1.793 12.6637 1.5 12 1.5ZM8.25 6.75C8.25 6.75 3 11.25 3 16.5C3 19.5 5.25 21 6.75 21H12H17.25C18.75 21 21 19.5 21 16.5C21 11.25 15.75 6.75 15.75 6.75H8.25ZM11.25 8.25H12.75V9.75586C14.145 9.78811 14.978 10.4881 14.978 11.6206C14.978 12.4156 14.3911 13.1077 13.6216 13.2217V13.3154C14.6146 13.3882 15.3369 14.1373 15.3369 15.104C15.3369 16.4165 14.3468 17.2463 12.75 17.2485V18.75H11.25V17.25H9.375V9.75H11.25V8.25ZM10.9453 10.9512V12.8584H12.1245C12.972 12.8584 13.4341 12.5107 13.4341 11.9077C13.4341 11.31 13.0034 10.9512 12.2549 10.9512H10.9453ZM10.9453 13.9189V16.0488H12.3428C13.2473 16.0488 13.7358 15.669 13.7358 14.9678C13.7358 14.2815 13.2309 13.9189 12.3062 13.9189H10.9453Z"
        fill="#64627A" />
    </svg>
  )
}
export default Bil;
