import React from "react";
const XIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.364 19.7782C18.7545 20.1687 19.3877 20.1687 19.7782 19.7782C20.1687 19.3877 20.1687 18.7545 19.7782 18.364L13.4142 12L19.7782 5.63606C20.1687 5.24554 20.1687 4.61237 19.7782 4.22185C19.3877 3.83132 18.7545 3.83132 18.364 4.22185L12 10.5858L5.63606 4.22185C5.24554 3.83132 4.61237 3.83132 4.22185 4.22185C3.83132 4.61237 3.83132 5.24554 4.22185 5.63606L10.5858 12L4.22185 18.364C3.83132 18.7545 3.83132 19.3877 4.22185 19.7782C4.61237 20.1687 5.24554 20.1687 5.63606 19.7782L12 13.4142L18.364 19.7782Z" fill="white"/>
    </svg>

  )
}
export default XIcon;
