import React from "react";
import { Link } from "react-router-dom";
import Button from "../core/Button";
import './NotFound.scss';
import img from '../../assets/images/notfound.png';
import fingerprintBg from "../../assets/images/fingerprint-left.svg";
const NotFound  =() => {
  return (
    <div className={'not-found-page'}>
      <div className={'design-elements'}>
        <img src={fingerprintBg} alt={''} />
      </div>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12 lg:col-6'}>
            <h1 className={'mb-32 lg:mb-64'}>Error 404 <br/> <span className={'text-light'}>Page not found</span> </h1>
            <p className={'h3 text-light mb-32'}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Oops! We're sorry, but it looks like the page you're looking for cannot be found. This may be because the page has been moved or deleted. Please go back to the HomePage.
            </p>
            <Link to={'/'}>
              <Button color={'secondary'}>Go to Homepage</Button>
            </Link>
          </div>
          <div className={'col-12 lg:col-4 lg:push-1'}>
            <img src={img} alt={''}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;
