import React, { Component, useCallback } from "react";
import "../Account.scss";
import Input from "../../../components/core/Input";
import Button from "../../../components/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { userSelector } from "../../SignIn";
import { updatePasswordAction, updateProfileAction } from "../redux/accountSlice";
// import EmailNot from "../../../components/core/Icons/EmailNot";

const schema = yup
  .object({
    email: yup.string().matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    ).required("Email is required"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required")
  });
const Information = () => {


  const dispatch = useDispatch()
  // const isLoading = useSelector(isSignInLoader)
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userSelector)
  const isLoading = false;

  const {register, handleSubmit, watch,
    formState: {
      isValid,
      errors
    }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email
    }
  });
  const onSubmit = useCallback((data) => {
    dispatch(updateProfileAction({
      data,
      navigate,
      location
    }));
  },[dispatch, location, navigate]);
  return (
    <section className={'account-section'}>
      <h4 className={'account-section-title'}>Personal information</h4>
      <div className={'account-section-body'}>
        <form>
          <div className={'row'}>
            <div className={'col-12 lg:col-6'}>
              <Input    error={errors.first_name?.message}
                        register={register}
                        placeholder="First name"
                        name="first_name"
                        id="fnameId"
                        defaultValue={user?.first_name}
                        label={'First Name'}/>
            </div>
            <div className={'col-12 lg:col-6'}>
              <Input    error={errors.last_name?.message}
                        register={register}
                        placeholder="Last name"
                        name="last_name"
                        id="lnameId"
                        defaultValue={user?.last_name}
                        label={'Last Name'}/>
            </div>
            <div className={'col-12 lg:col-6'}>
              <Input    error={errors.email?.message}
                        register={register}
                        placeholder="Email"
                        name="email"
                        id="emailId"
                        defaultValue={user?.email}
                        label={'Email'}/>
            </div>
            <div className={'col-12 lg:col-6'}>
              {/*<div className={'account-not-confirmed mb-32'}>*/}
              {/*  <EmailNot />*/}
              {/*  <span>Your email is not verified. Please check your email and follow your instruction to verify your email</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={'flex justify-end mt-8 space-y-16 xs:space-y-0 xs:space-x-32 flex-col xs:flex-row'}>
            {/*<Button size={'small'} color={'secondary'}>Resend verification email</Button>*/}
            <Button onClick={handleSubmit(onSubmit)} disabled={isLoading || !isValid} size={'small'}>Save Changes</Button>
          </div>
        </form>
      </div>
    </section>
  );
};
export default Information;
