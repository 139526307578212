import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loader: false,
  isAuth: false,
};

export const verifySlice = createSlice({
  name: "verify",
  initialState,
  reducers: {
    verifyAction: (state, action) => {
      state.loader = true;
    },

    verifyActionSuccess: (state, action) => {
      state.loader = false;
      state.user = action.payload;
      state.isAuth = true;
    },
    verifyActionError: (state) => {
      state.loader = false;
      state.user = null;
      state.isAuth = false;
    },
  },
});

export const {
  verifyAction, verifyActionError, verifyActionSuccess
} = verifySlice.actions;
