import { call, put, takeEvery } from "@redux-saga/core/effects";
import registerService from "../services/registerService";
import { registerAction, registerActionError, registerActionSuccess } from "./registerSlice";
import { hide, show } from "../../../components/core/Modal";
import { toast } from "react-toastify";


function * registerSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        registerService.register(payload.data),
    );
    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(registerActionError());
    } else {
      payload.navigate(`${payload.location.pathname}?verify`);
      hide("setPasswordModal")
      show("verifyEmailModal")
      yield put(registerActionSuccess(response.payload));

    }

  } catch (error) {
    yield put(registerActionError());
  }
}

export function * watchFetchRegister () {
  yield takeEvery(registerAction.type, registerSaga);
}

