import React, { useCallback, useEffect, useState } from "react";
import "../Account.scss";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../SignIn";
import { downloadHistoryListItem, getDownloadHistoryAction } from "../../Database";
import { downloadsHistoryListSelector } from "../../Database/redux/selectors";
import DownloadCookies from "../../../components/core/Icons/DownloadCookies";
import Download from "../../../components/core/Icons/Download";
import FingerprintIcon from "../../../components/core/Icons/Fingerprint";
import moment from 'moment';

const History = () => {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const historyList = useSelector(downloadsHistoryListSelector)

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(historyList?.count / ITEMS_PER_PAGE)
  useEffect(() => {

    if (user) {
      dispatch(getDownloadHistoryAction({ offset: 0, limit: 8 }))
    }
  }, [dispatch, user])
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(getDownloadHistoryAction({ offset:( pageNumber -1 )* 8, limit: 8 }))
  };
  const onSubmitDownload = useCallback((id, type) => {
    dispatch(downloadHistoryListItem({id, type}))
  }, [dispatch])
  return (
    <>
      <section className={'account-section history'}>
          <div className={"container"}>
            <div className={"responsive-table history"}>
              <table>
                <thead>
                <tr>
                  <th>Date & Time</th>
                  <th>Quantity</th>
                  <th>Cookies</th>
                  <th>FPs</th>
                  <th>Profiles</th>
                </tr>
                </thead>
                <tbody>
                {historyList && historyList?.history.map((fingerPrint, index) => {
                  return <tr key={index}>
                    <td>{moment(fingerPrint.created_at).format("MMM Do YYYY, h:mm:ss a")}</td>
                    <td>{fingerPrint?.cost}</td>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <td className={"download_icon_cookies"}
                        onClick={() => onSubmitDownload(fingerPrint?.id, "cookies") }
                    ><DownloadCookies /></td>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <td className={"download_icon_fps"}
                      onClick={() => onSubmitDownload(fingerPrint?.id, "fingerprint") }
                    ><FingerprintIcon /></td>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <td className={"download_icon_profile"}
                      onClick={() => onSubmitDownload(fingerPrint?.id)}
                    ><Download /></td>
                  </tr>
                })}
                </tbody>
              </table>
            </div>
          </div>
        <nav className={'pagination mt-32'}>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber, index) => (
            <button key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
            >
              <p className={page === index+1 && 'active'}>{pageNumber}</p>
            </button>
          ))}
        </nav>
        </section>
    </>
  );
};
export default History;
