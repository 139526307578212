import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  updatePasswordAction,
  updatePasswordError,
  updatePasswordSuccess, updateProfileAction, updateProfileError, updateProfileSuccess
} from "./accountSlice";
import { show } from "../../../components/core/Modal";
import { toast } from "react-toastify";
import accountService from "../services/accountService";
import { getUserAction, logOutAction, resetState } from "../../SignIn";


function * updatePasswordSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        accountService.updatePassword(payload.data),
    );
    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(updatePasswordError());
    } else {
      yield put(logOutAction());
      payload.navigate(`${payload.location.pathname}?signIn`);
      show("signInModal")
      yield put(updatePasswordSuccess());

    }

  } catch (error) {
    yield put(updatePasswordError());
  }
}
function * updateProfileSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        accountService.updateProfile(payload.data),
    );
    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(updateProfileError());
    } else {
      if (response.payload.emailChanged) {
        payload.navigate("/")
        show("infoModalEmail")
        localStorage.removeItem("token")
        sessionStorage.removeItem("token")
        yield put(resetState());

      } else {
        yield put(getUserAction());
      }
      yield put(updateProfileSuccess());
    }

  } catch (error) {
    yield put(updateProfileError());
  }
}

export function * watchFetchAccount () {
  yield takeEvery(updatePasswordAction.type, updatePasswordSaga);
  yield takeEvery(updateProfileAction.type, updateProfileSaga);
}

