import React, { useCallback } from "react";
import "./SetPasswordModal.scss";
import Modal, { hide, show } from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";
import CheckBox from "../../../../components/core/CheckBox";

const SetPasswordModal = ({ onSubmit, register, isLoading, passwordWatch, isValid }) => {
  const showVerifyEmailModal = useCallback(() => {
    hide("setPasswordModal");
    show("verifyEmailModal");
  }, []);

  return <Modal shouldCloseOnOverlayClick={false} id={"setPasswordModal"} title={"Set a Password"}>

    {({modalData}) => {
      return (
        <form autoComplete="off" className={'set-password-form'}>
          <Input value={modalData?.name} name={"first_name"} type={"hidden"} register={register}/>
          <Input value={modalData?.lastName} name={"last_name"} type={"hidden"} register={register}/>
          <Input value={modalData?.email} name={"email"} type={"hidden"} register={register}/>
          <Input
            register={register}
            placeholder="Password"
            name="password"
            id="passwordId"
            type={'password'}
          />

          <p className={'c1 text-light text-secondary mb-15'}>Your password should contain at least:</p>
          <CheckBox label={"One uppercase character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*[A-Z])/)} readOnly />
          <CheckBox label={"One lowercase character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*[a-z])/)} readOnly/>
          <CheckBox label={"One number"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*\d)/)} readOnly/>
          <CheckBox label={"One special character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*\W+)/)} readOnly/>
          <CheckBox label={"Min 8 characters"} className={'mb-20'} checked={passwordWatch && passwordWatch.length >= 8} readOnly/>
          <div className={'px-16 lg:px-0'}>
            <Button
              loading={isLoading}
              disabled={isLoading || !isValid}
              // onClick={onSubmit}
              // type="submit"
              onClick={onSubmit}
              className={"w-full mt-32"}
            >
              Set Password
            </Button>
          </div>
        </form>
      );
    }}



  </Modal>;
};

SetPasswordModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  passwordWatch: PropTypes.any.isRequired,
  isValid: PropTypes.bool.isRequired
};

export default SetPasswordModal;
