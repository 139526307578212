import { call, put, takeEvery } from "@redux-saga/core/effects";
import { hide, show } from "../../../components/core/Modal";
import forgotService from "../services/forgotService";
import { forgotAction, forgotActionError } from "./forgotSlice";
import { toast } from "react-toastify";


function * forgotSaga ({ payload }) {
  try {
    const response = yield call(
      () =>
        forgotService.forgot(payload.data),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(forgotActionError());
    } else {
      payload.navigate(`${payload.location.pathname}?success`);
      hide("forgotPasswordModal")
      show("successModalEmail")

    }

  } catch (error) {
    yield put(forgotActionError());
  }
}

export function * watchFetchForgot () {
  yield takeEvery(forgotAction.type, forgotSaga);
}

