import React from "react";
import "./JoinTelegram.scss";
import Button from "../../components/core/Button";
import Modal from "../../components/core/Modal";
import telegramIcon from "../../assets/images/icons/telegram.svg";

const JoinTelegram = () => {

  return <Modal shouldCloseOnOverlayClick={false} id={"joinTelegramModal"} title={"Join Telegram"}>
    <form action="https://t.me/+wexvM_XLMlk1N2U8" method="get" target="_blank">
      <div className={"joinTelegramContainer"}>
      <img src={telegramIcon} alt={""} />
      <p className={'c1 text-light text-primary mb-17'}>Hey, We are on Telegram!</p>
      </div>
      <div className={'px-16 lg:px-0'}>
        <Button
          type="submit"
          className={"w-full mt-32"}
        >
          Join Now!
        </Button>
      </div>
    </form>
  </Modal>;
};

export default JoinTelegram;
