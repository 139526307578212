import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

export const resetSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetAction: (state, action) => {
      state.loader = true;
    },

    resetActionSuccess: (state, action) => {
      state.loader = false;
    },
    resetActionError: (state) => {
      state.loader = false;
    },
  },
});

export const {
  resetAction,
  resetActionSuccess,
  resetActionError,
} = resetSlice.actions;
