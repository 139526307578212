import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    loader: false,
    getUserLoader: false,
    sendEmailLoader: false,
    isAuth: false,
};

export const signInSlice = createSlice({
    name: "signIn",
    initialState,
    reducers: {
        signInAction: (state, action) => {
            state.loader = true;
        },


        getUserAction: (state, action) => {
            state.getUserLoader = true;
        },

        sendEmailAction: (state, action) => {
            state.sendEmailLoader = true;
        },

        sendEmailSuccess: (state) => {
            state.sendEmailLoader = false;
        },

        sendEmailError: (state) => {
            state.sendEmailLoader = false;
        },

        logOutAction: (state, action) => {
            state.loader = false;
            state.isAuth= false
        },

        signInActionSuccess: (state, action) => {
            state.loader = false;
            state.user = action.payload;
            state.isAuth = true;
            state.getUserLoader = false;
        },
        signInActionError: (state) => {
            state.loader = false;
            state.getUserLoader = false;
            state.user = null;
            state.isAuth = false;
        },

        resetState: (state) => {
            state.loader = false;
            state.getUserLoader = false;
            state.user = null;
            state.isAuth = false;
        },
    },
});

export const {
    signInActionSuccess,
    signInActionError,
    signInAction,
    getUserAction,
    logOutAction,
    resetState,
    sendEmailAction,
    sendEmailSuccess,
    sendEmailError,
} = signInSlice.actions;
