import React from "react";
import "./ResendCodeModal.scss";
import Modal from "../../../../components/core/Modal";
import Input from "../../../../components/core/Input";
import Button from "../../../../components/core/Button";
import PropTypes from "prop-types";

const ResendCodeModal = ({ onSubmit, register, errors, isLoading }) => {
  return <Modal shouldCloseOnOverlayClick={false} id={"resendCodeModal"} title={"Resend the code"}>
    <form autoComplete="off" className={'verify-email-form'}>
      <p className={'c1 text-light text-secondary mb-15'}>Please enter your email to receive the verification code</p>
      <Input
        error={errors.email?.message}
        register={register}
        placeholder="Email"
        name="email"
        id="emailId"
      />
      <div className={'px-16 lg:px-0'}>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
          type="submit"
          className={"w-full mt-16"}
        >
          Resend
        </Button>
      </div>
    </form>
  </Modal>;
};

ResendCodeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired
};

export default ResendCodeModal;
