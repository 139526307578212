import React, { useEffect } from "react";
import SignInModal from "../components/LoginModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {useCallback} from "react";
import {signInAction} from "../redux/signInSlice";
import { isSignInLoader } from "../redux/selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { hide, show } from "../../../components/core/Modal";


const schema = yup.object().shape({
    email: yup.string().matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    ).required("Email is required"),
    password: yup.string().required("Password is required").min(8, "min 8 symbols")
});

const SignInContainer = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(isSignInLoader)
    const location = useLocation()
    const navigate = useNavigate()
    const {register, handleSubmit,resetField,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
        }
    });
    useEffect(() => {
        if (location.search) {
            resetField("email")
            resetField("password")
            resetField("isRemember")
        }
    },[location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(signInAction({
            data,
        }));
    },[dispatch]);

    const showSignUpModal = useCallback(() => {
        navigate(`${location.pathname}?createAccount`)
        hide("signInModal");
        show("signUpModal");
    }, [location.pathname, navigate]);

    const showForgotPasswordModal = useCallback(() => {
        navigate(`${location.pathname}?forgotPassword`)
        hide("signInModal");
        show("forgotPasswordModal");
    }, [location.pathname, navigate]);

    return <SignInModal showForgotPasswordModal={showForgotPasswordModal}
                        showSignUpModal={showSignUpModal}
                        isLoading={isLoading}
                        errors={errors}
                        register={register}
                        isValid={isValid}
                        onSubmit={handleSubmit(onSubmit)}/>
}
export default SignInContainer
