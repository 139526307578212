import ServiceBase from "../../../services/apiServiceBase";

class RegisterService extends ServiceBase {
  async register(request) {
    return await this.post(`${this.apiUrl}/user/register`, request);
  }

}

export default new RegisterService();
