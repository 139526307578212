
export const DEVICE_TYPE = [
  {
    value: 'mobile',
    label: 'Mobile'
  },
  {
    value: 'desktop',
    label: 'Desktop'
  },
];

export const OS = [
  {
    value: 'windows',
    label: 'Windows'
  },
  {
    value: 'mac os',
    label: 'MacOS'
  },
  {
    value: 'ios',
    label: 'IOS'
  },
  {
    value: 'android',
    label: 'Android'
  },
  {
    value: 'ubuntu',
    label: 'Ubuntu'
  },
];

export const COOKIE_OPTIONS = [
  {
    value: 'mix',
    label: 'Mix'
  },
  {
    value: 'google',
    label: 'Google'
  },
  {
    value: 'facebook',
    label: 'Facebook'
  },
  {
    value: 'bing',
    label: 'Bing'
  },
  {
    value: "ebay",
    label: "eBay"

  },
  {
    value: "etsy",
    label: "Etsy"
  },
  {
    value: "amazon",
    label: "Amazon"
  },


];

export const COOKIE_AGE = [

  {
    value: 4,
    label: 'This month'
  },
  {
    value: 3,
    label: '2 month'
  },
  {
    value: 2,
    label: '3 month'
  },
  {
    value: 1,
    label: '4 month'
  },
  {
    value: 12,
    label: '5 month'
  },
  {
    value: 11,
    label: '6 month'
  },
  {
    value: 10,
    label: '7 month'
  },
  {
    value: 9,
    label: '8 month'
  },
  {
    value: 8,
    label: '9 month'
  },

];

export const COUNTRY = [
  {
    value: "USA",
    label: 'USA'
  },
  {
    value: "France",
    label: 'France'
  },
  {
    value: 'Germany',
    label: 'Germany'
  },
  {
    value: 'uk',
    label: 'UK'
  },
];
export const INTEREST = [
  {
    value: "art",
    label: 'Art'
  },
  {
    value: 'e-commerce',
    label: 'E-commerce'
  },
  {
    value: 'entertainment',
    label: 'Entertainment'
  },
  {
    value: 'business',
    label: 'Business'
  },
  {
    value: 'news',
    label: 'News'
  },
  {
    value: 'music',
    label: 'Music'
  },
  {
    value: 'technologies',
    label: 'Technologies'
  },
  {
    value: 'health-beauty',
    label: 'Health-Beauty'
  },
  {
    value: 'sports',
    label: 'Sports'
  },
];

export const BROWSER = [
  {
    value: 'firefox',
    label: 'Firefox'
  },
  {
    value: 'chrome',
    label: 'Google Chrome'
  },
  {
    value: 'safari',
    label: 'Safari'
  },
  {
    value: 'edge',
    label: 'Microsoft Edge'
  },
];

export const SCREEN_RESOLUTION = [
  {
    value: '1920x1080',
    label: '1920x1080'
  },
  {
    value: '1600x900',
    label: '1600x900'
  },
  {
    value: '1536x864',
    label: '1536x864'
  },
  {
    value: '1440x900',
    label: '1440x900'
  },
  {
    value: '1366x768',
    label: '1366x768'
  },
  {
    value: '1280x720',
    label: '1280x720'
  },
  {
    value: '1024x768',
    label: '1024x768'
  },
  {
    value: '414x896',
    label: '390x844'
  },
  {
    value: '428x926',
    label: '428x926'
  },
  {
    value: '430x932',
    label: '430x932'
  },
  {
    value: '393x852',
    label: '393x852'
  },
  {
    value: '390x844',
    label: '390x844'
  },
  {
    value: '375x667',
    label: '375x667'
  },
  {
    value: '375x812',
    label: '375x812'
  },
  {
    value: '412x771',
    label: '412x771'
  },

];

export const BUY_PACK_MARKS = {
  1: {
    value: 30,
    label: 'Basic'
  },
  2: {
    value: 50,
    label: ' '
  },
  3: {
    value: 100,
    label: ' '
  },
  4: {
    value: 500,
    label: ' '
  },
  5: {
    value: 1000,
    label: ' '
  },
  6: {
    value: 5000,
    label: ' '
  },
  7: {
    value: 10000,
    label: ' '
  },
  8: {
    value: 100000,
    label: ' '
  },
  9: {
    value: 500000,
    label: ' '
  },
  10: {
    value: 1000000,
    label: 'Pro'
  },
};

export const BASIC_OPTIONS = [
  {label: 30, count: 30, value: 10},
  {label: 50, count: 50, value: 13},
  {label: 100, count: 100, value: 25},
  {label: 500,count: 500, value: 90},
  {label: "1K",count: 1000, value: 150},
]

export const PRO_OPTIONS = [
  {label: "5K", count: 5000, value: 300},
  {label: "10K",count: 10000, value: 500},
  {label: "100K",count: 100000, value: 1000},
  {label: "500K",count: 500000, value: 2000},
  {label: "1KK",count: 1000000, value: 3000},
]

export const HOME_PAGE_MARKS  = {
  1: {
    value: 10,
    label: 'Free'
  },
  2: {
    value: 30,
    label: ' '
  },
  3: {
    value: 50,
    label: ' '
  },
  4: {
    value: 100,
    label: ' '
  },
  5: {
    value: 500,
    label: ' '
  },
  6: {
    value: 1000,
    label: 'Basic'
  },
  7: {
    value: 5000,
    label: ' '
  },
  8: {
    value: 10000,
    label: ' '
  },
  9: {
    value: 100000,
    label: ' '
  },
  10: {
    value: 500000,
    label: ' '
  },
  11: {
    value: 1000000,
    label: 'Pro'
  },
}
