import ServiceBase from "../../../services/apiServiceBase";

class PricingService extends ServiceBase {
  async createInvoice(request) {
    return await this.post(`${this.apiUrl}/payments/invoice`, request);
  }

  async addInvoice(request) {
    return await this.post(`${this.apiUrl}/payments/add`, request);
  }

  async getFree() {
    return await this.post(`${this.apiUrl}/user/get-free-credit`);
  }

  async getApiKey() {
    return await this.get(`${this.apiUrl}/user/get-token`);
  }

}

export default new PricingService();
