import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import {
  dataBaseActionError,
  getFilteredListAction,
  getHomePageList,
  getHomePageListSuccess,
  getListAction,
  getListActionError,
  getListActionSuccess,
  getSeveralFingerPrints,
  getSingleFingerPrints,
  getSingleCookiesFingerPrints,
  getSeveralCookiesFingerPrints,
  getDownloadHistoryActionSuccess,
  getDownloadHistoryAction,
  downloadHistoryListItem, resetLoader
} from "./dataBaseSlice";
import { toast } from "react-toastify";
import dataBaseService from "../services/dataBaseService";
import { show } from "../../../components/core/Modal";
import { getUserAction, userSelector } from "../../SignIn";

function * getListSaga ( ) {
  try {
    const response = yield call(
      () =>
        dataBaseService.getList(),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(getListActionError());
      yield put(resetLoader());
    } else {
      yield put(getListActionSuccess(response?.payload?.fingerprints));
      yield put(resetLoader());
    }

  } catch (error) {
    yield put(getListActionError());
    yield put(resetLoader());
  }
}

function * getHomePageListSaga ( ) {
  const user = yield select(userSelector);
  try {
    const response = yield call(
      () =>
        dataBaseService.getHomePageList(user),
    );

    if (response?.hasError) {
      yield put(dataBaseActionError());
    } else {
      yield put(getHomePageListSuccess(response?.payload?.fingerprints));
    }

  } catch (error) {
    yield put(dataBaseActionError());
  }
}
function * getFilteredListSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getFilterList(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(getListActionError());
    } else {
      yield put(getListActionSuccess(response?.payload?.fingerprints));
    }

  } catch (error) {
    yield put(getListActionError());
  }
}

function * getSingleFingerPrintSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getSingleFingerPrint(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
      yield put(resetLoader());
    } else {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(response.payload)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `${data?.payload?.id}.json`;
      link.click();
      yield put(getUserAction());
      yield put(getHomePageList());
      yield put(getListAction());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}

function * getSingleCookiesFingerPrintsSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getSingleCookiesFingerPrints(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
      yield put(resetLoader());
    } else {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(response.payload)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `${data?.payload?.id}.json`;
      link.click();
      yield put(getUserAction());
      yield put(getHomePageList());
      yield put(getListAction());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}

function * getSeveralFingerPrintsSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getSeveralFingerPrints(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
      yield put(resetLoader());
    } else {
      show("downloadSuccessModal")
      yield put(getListAction());
      yield put(getUserAction());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}

function * getSeveralCookiesFingerPrintsSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getSeveralCookiesFingerPrints(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
      yield put(resetLoader());
    } else {
      show("downloadSuccessModal")
      yield put(getListAction());
      yield put(getUserAction());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}
function * getDownloadsHistorySaga (data) {

  try {
    const response = yield call(
      () =>
        dataBaseService.getDownloadsHistory(data.payload),
    );

    if (response?.hasError) {
      yield put(resetLoader());
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
    } else {
      yield put(getDownloadHistoryActionSuccess(response.payload));
      yield put(resetLoader());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}


function * downloadHistoryListItemSaga (data ) {

  try {
    const response = yield call(
      () =>
        dataBaseService.downloadHistoryListItem(data.payload),
    );

    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(dataBaseActionError());
      yield put(resetLoader());
    } else {
      show("downloadSuccessModal", { imModalData: true })
      yield put(resetLoader());
    }

  } catch (error) {
    yield put(dataBaseActionError());
    yield put(resetLoader());
  }
}

export function * watchFetchDataBase () {
  yield takeEvery(getListAction.type, getListSaga);
  yield takeEvery(getFilteredListAction.type, getFilteredListSaga);
  yield takeEvery(getSingleFingerPrints.type, getSingleFingerPrintSaga);
  yield takeEvery(getSingleCookiesFingerPrints.type, getSingleCookiesFingerPrintsSaga);
  yield takeEvery(getSeveralFingerPrints.type, getSeveralFingerPrintsSaga);
  yield takeEvery(getSeveralCookiesFingerPrints.type, getSeveralCookiesFingerPrintsSaga);
  yield takeEvery(getHomePageList.type, getHomePageListSaga);
  yield takeEvery(getDownloadHistoryAction.type, getDownloadsHistorySaga);
  yield takeEvery(downloadHistoryListItem.type, downloadHistoryListItemSaga);
}

