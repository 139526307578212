import React, { useCallback } from "react";
import "../Account.scss";
import Input from "../../../components/core/Input";
import Button from "../../../components/core/Button";
import CheckBox from "../../../components/core/CheckBox";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { updatePasswordAction } from "../redux/accountSlice";

const schema = yup
  .object({
    oldPassword: yup
      .string()
      .required("Field is mandatory")
      .min(8, "Password must be at least 8 characters long"),
    newPassword: yup
      .string()
      .required("Field is mandatory")
      .min(8, "")
      .matches(/(?=.*\d)/, "")
      .matches(/(?=.*[A-Z])/, "")
      .matches(/(?=.*\W+)/, "")
      .matches(/(?=.*[a-z])/, "")
  });
const Password = () => {

  const dispatch = useDispatch()
  // const isLoading = useSelector(isSignInLoader)
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = false;
  const {register, handleSubmit, watch,
    formState: {
      isValid,
      errors
    }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: "",
      newPassword: ""
    }
  });

  const passwordWatch = watch("newPassword")
  const oldPasswordWatch = watch("oldPassword")

  const onSubmit = useCallback((data) => {
    dispatch(updatePasswordAction({
      data,
      navigate,
      location
    }));
  },[dispatch, location, navigate]);

  return (
    <section className={'account-section'}>
      <h4 className={'account-section-title'}>Change Password</h4>
      <div className={'account-section-body'}>
        <form autoComplete="off">
          <div className={'row'}>
            <div className={'col-12 lg:col-6'}>
              <Input id="passwordId"
                     register={register}
                     name={"oldPassword"}
                     error={errors.oldPassword?.message}
                     placeholder={'Enter Your current Password'}
                     type={'password'}/>
            </div>
            <div className={'col-12 lg:col-6'}>
              <Input id="passwordId"
                     register={register}
                     name={"newPassword"}
                     error={oldPasswordWatch && oldPasswordWatch.length > 0 && oldPasswordWatch === passwordWatch &&  "Your new password cannot be the same as your old password."}
                     placeholder={'Enter a new Password'}
                     type={'password'}/>

              <div className={'password-check'}>
                <p className={'c1 text-light text-secondary mb-15'}>Your password should contain at least:</p>
                <CheckBox label={"One uppercase character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*[A-Z])/)} readOnly />
                <CheckBox label={"One lowercase character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*[a-z])/)} readOnly/>
                <CheckBox label={"One number"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*\d)/)} readOnly/>
                <CheckBox label={"One special character"} className={'mb-20'} checked={!!passwordWatch.match(/(?=.*\W+)/)} readOnly/>
                <CheckBox label={"Min 8 characters"} className={'mb-20'} checked={passwordWatch && passwordWatch.length >= 8} readOnly/>
              </div>
            </div>
          </div>
          <div className={'flex justify-end mt-8'}>
            <Button  loading={isLoading}
                     disabled={isLoading || !isValid || oldPasswordWatch ===passwordWatch }
                     onClick={handleSubmit(onSubmit)}
                     size={'small'}>Save Changes</Button>
          </div>
        </form>
      </div>
    </section>
  );
};
export default Password;
