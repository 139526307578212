import React, { useCallback, useEffect, useState } from "react";
import Button from "../core/Button";
import PropTypes from "prop-types";
import Select from "react-dropdown-select";
import "./Pack.scss";
import { useDispatch, useSelector } from "react-redux";
import { createInvoiceAction, getFree } from "../../modules/Pricing";
import { userSelector } from "../../modules/SignIn";
import { useLocation, useNavigate } from "react-router-dom";
import { show } from "../core/Modal";
import { BASIC_OPTIONS, PRO_OPTIONS } from "../../constants/constants";
import { isPriceLoader } from "../../modules/Pricing/redux/selectors";

const Pack = ({
                packType,
                color,
                defaultCount,
                options,
                features,
                activeFeatures,
                // perPrice,
                bordered,
                noDetails
              }) => {
  const packName = (
    color === "free" ? "FREE" :
      color === "basic" ? "BASIC" :
        color === "pro" ? "PRO" : ""
  );
  const packColor = (
    color === "free" ? "orange" :
      color === "basic" ? "blue" :
        color === "pro" ? "purple" : ""
  );
  const packButtonName = (
    color === "free" ? "Start for Free" :
      color === "basic" ? "Buy now" :
        color === "pro" ? "Buy now" : ""
  );
  const dispatch = useDispatch()
  const [price, setPrice] = useState(options[0]?.value);
  const [perPrice, setPerPrice] = useState();
  const user = useSelector(userSelector)
  const isLoader = useSelector(isPriceLoader)
  const navigate = useNavigate()
  const location = useLocation();

  const calculatePrice = (count) => {
    if(count[0]?.count > 100000) {
      setPrice(count[0]?.value);
      setPerPrice((count[0]?.value / count[0]?.count).toFixed(3))
    } else {
      setPrice(count[0]?.value);
      setPerPrice((count[0]?.value / count[0]?.count).toFixed(2))
    }

  };
  useEffect(() => {
    setPerPrice((options[0]?.value / options[0]?.count).toFixed(2))
  }, [options]);

  const onSubmit = useCallback(() => {
    if (!user) {
      navigate(`${location.pathname}?signIn`)
      show("signInModal")
    } else {

      if ( !user?.free_credits && !price ) {
        dispatch(getFree())
      }
      else {
        dispatch(createInvoiceAction({amount: price }))
      }
    }

  }, [dispatch, location.pathname, navigate, price, user])

  const isDisabled = ( user?.free_credits && !price)

  useEffect(() => {
    if (defaultCount) {
      if (defaultCount < 5000) {
        const item = BASIC_OPTIONS.find((el) => el.count === defaultCount)
        setPrice(item?.value);
        setPerPrice((item?.value / item?.count).toFixed(2))
      } else if(defaultCount >=5000) {
        const item = PRO_OPTIONS.find((el) => el.count === defaultCount)
        if(defaultCount > 100000) {
          setPrice(item?.value);
          setPerPrice((item?.value / item?.count).toFixed(3))
        } else {
          setPrice(item?.value);
          setPerPrice((item?.value / item?.count).toFixed(2))
        }
      }
    }
  }, [defaultCount])

  const handleDefaultCount = (count) => {
    switch (count) {
      case 1000:
        return "1k";
      case 5000:
        return "5k";
      case 10000:
        return "10k";
      case 100000:
        return "100K";
      case 500000:
        return "500K";
      case 1000000:
        return "1KK";
      default:
        return count;
    }
  }

  return (
    <div className={`pack-card pack-${packColor} ${bordered && 'pack-bordered'}`}>
      <div className={"pack-badge"}><span>{packName}</span> Pack</div>
      <div className={"pack-price-wrapper"}>
        <div className={"pack-price-count"}>
          {packType === "static" && <span className={"pack-price-number"}>{handleDefaultCount(defaultCount)}</span>}
          {
            packType === "selectbox" && <Select searchable={false} multi={false} values={[options[0]]}
                                                onChange={(values) => calculatePrice(values)}
                                                options={options} />
          }
          <span className={"pack-price-text"}>fingerprints</span>
        </div>
        {color === "free" && <div className={"pack-price free"}>FREE</div>}
        {color !== "free" &&
          <div className={"pack-price"}><span>$ {price}</span><span>$ {perPrice} per fingerprint</span></div>}
      </div>
      {!noDetails &&
        <div className={"pack-includes"}>
          {
            features.map((f, i) => {
              return (
                <p key={i} className={activeFeatures.indexOf(i) !== -1 && "active"}>{f}</p>
              );
            })
          }
        </div>
      }
      <Button disabled={isDisabled || isLoader} loading={isLoader} onClick={onSubmit} color={"primary"}>{packButtonName}</Button>
    </div>
  );
};


Pack.displayName = "Pack";

Pack.defaultProps = {
  packType: "static",
  color: "free",
  defaultCount: 10,
  options: [],
  features: [
    "Direct downloads",
    "API calls"
  ],
  activeFeatures: [],
  // perPrice: 0,
  bordered: false,
  noDetails: false,
};

Pack.propTypes = {
  /** Pack Type */
  packType: PropTypes.oneOf(["static", "selectbox", "slide"]),
  /** Pack color */
  color: PropTypes.oneOf(["free", "basic", "pro"]),
  /** Pack default fingerprint count */
  defaultCount: PropTypes.number,
  /** Pack fingerprint count selectbox */
  options: PropTypes.arrayOf(PropTypes.number),
  /** Pack features */
  features: PropTypes.arrayOf(PropTypes.string),
  /** Pack active features */
  activeFeatures: PropTypes.arrayOf(PropTypes.number),
  /** Pack per one price */
  // perPrice: PropTypes.number,
  /** Pack per one price */
  bordered: PropTypes.bool,
  noDetails: PropTypes.bool,
};

export default Pack;
