import React, { useCallback } from "react";
import "./SuccessModal.scss";
import Modal, { hide, show } from "../core/Modal";
import DownloadBig from "../core/Icons/DownloadBig";
import Button from "../core/Button";
import PropTypes from "prop-types";
import EmailSuccess from "../core/Icons/EmailSuccess";
import PasswordSuccess from "../core/Icons/PasswordSuccess";
import { useNavigate } from "react-router-dom";

const SuccessModal = ({icon, title, description, buttonName }) => {
  const navigate = useNavigate()
  return <Modal id={`successModalEmail`}>
    <div className={'success-text'}>
      {title && <h4>{title}</h4> }
      {icon === 'download' &&  <DownloadBig /> }
      {icon === 'email' &&  <EmailSuccess /> }
      {icon === 'password' &&  <PasswordSuccess /> }
      {description && <p>{description}</p>}
      <Button size={"small"} color={"secondary"} onClick={() => {
        navigate(`${location.pathname}?forgotPassword`)
        show(`forgotPasswordModal`);
        hide(`successModalEmail`);
      }}>Didn’t receive email? Resend instructions</Button>
    </div>
  </Modal>;
};

SuccessModal.defaultProps = {
  icon: 'download',
  title: null,
  description: null,
  buttonName: 'Okay',
};

SuccessModal.propTypes = {
  icon: PropTypes.oneOf(['email', 'download', 'password']),
  title: PropTypes.string,
  description: PropTypes.string,
  buttonName: PropTypes.string,
};

export default SuccessModal;
