import React from "react";
const EmailSuccess = () => {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="104" height="104" rx="52" fill="url(#paint0_linear_145_12923)"/>
      <g filter="url(#filter0_i_145_12923)">
        <path fillRule="evenodd" clipRule="evenodd" d="M52 104C80.7188 104 104 80.7188 104 52C104 23.2812 80.7188 0 52 0C23.2812 0 0 23.2812 0 52C0 80.7188 23.2812 104 52 104ZM52 96C76.3005 96 96 76.3005 96 52C96 27.6995 76.3005 8 52 8C27.6995 8 8 27.6995 8 52C8 76.3005 27.6995 96 52 96Z" fill="url(#paint1_angular_145_12923)"/>
      </g>
      <path d="M27 35V69H54C54.3594 69.0039 54.6953 68.8164 54.8789 68.5039C55.0586 68.1914 55.0586 67.8086 54.8789 67.4961C54.6953 67.1836 54.3594 66.9961 54 67H29V64.7656L43.0156 53.8555C44.082 54.7578 46.2422 56.6133 46.5469 56.8594C46.5508 56.8594 46.5508 56.8633 46.5547 56.8633C48.1602 58.2344 49.8789 58.5977 51 58.5977C52.1211 58.5977 53.8398 58.2344 55.4453 56.8633C55.75 56.6211 57.9102 54.7617 58.9727 53.8594L61.875 56.1836C62.3047 56.5273 62.9375 56.457 63.2812 56.0273C63.625 55.5938 63.5586 54.9648 63.125 54.6211L60.5273 52.543C62.3711 50.9727 63.2852 50.2109 65.5625 48.2617C69.3594 45.0117 71.8477 42.875 73 41.8867V56C72.9961 56.3594 73.1836 56.6953 73.4961 56.8789C73.8086 57.0586 74.1914 57.0586 74.5039 56.8789C74.8164 56.6953 75.0039 56.3594 75 56V35H27ZM29 37H73V39.2539C72.7656 39.4531 68.7227 42.9258 64.2617 46.7383C59.6758 50.6641 54.8164 54.8047 54.1758 55.3164L54.1602 55.3281L54.1484 55.3398C52.9531 56.3633 51.6797 56.5977 51 56.5977C50.3203 56.5977 49.0469 56.3633 47.8516 55.3398L47.8398 55.3281L47.8242 55.3164C47.1836 54.8047 42.3242 50.6641 37.7383 46.7383C33.2773 42.9258 29.2344 39.4531 29 39.2539V37ZM29 41.8867C30.1523 42.875 32.6406 45.0117 36.4375 48.2617C38.7109 50.2031 39.6172 50.9648 41.457 52.5312L29 62.2344V41.8867ZM67 57C61.4883 57 57 61.4883 57 67C57 72.5117 61.4883 77 67 77C72.5117 77 77 72.5117 77 67C77 61.4883 72.5117 57 67 57ZM67 59C71.4297 59 75 62.5703 75 67C75 71.4297 71.4297 75 67 75C62.5703 75 59 71.4297 59 67C59 62.5703 62.5703 59 67 59ZM71.25 62.3438L65.8438 68.625L62.5938 66.1875L61.4062 67.8125L66.1562 71.375L66.75 70.6562L72.75 63.6562L71.25 62.3438Z" fill="url(#paint2_linear_145_12923)"/>
      <defs>
        <filter id="filter0_i_145_12923" x="0" y="0" width="104" height="106" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_145_12923"/>
        </filter>
        <linearGradient id="paint0_linear_145_12923" x1="52" y1="9" x2="52" y2="96" gradientUnits="userSpaceOnUse">
          <stop stopColor="#292A33"/>
          <stop offset="1" stopColor="#191A21"/>
        </linearGradient>
        <radialGradient id="paint1_angular_145_12923" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52 52) rotate(90) scale(46.5)">
          <stop stopColor="#13141A"/>
          <stop offset="0.498478" stopColor="#1E1F24"/>
          <stop offset="1" stopColor="#15161A"/>
        </radialGradient>
        <linearGradient id="paint2_linear_145_12923" x1="77" y1="35" x2="20.0948" y2="49.0677" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0B056"/>
          <stop offset="1" stopColor="#CD6A28"/>
        </linearGradient>
      </defs>
    </svg>

  )
}
export default EmailSuccess;
