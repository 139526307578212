import React, { useEffect } from "react";
import SetPasswordModal from "../components/SetPasswordModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useCallback} from "react";
import { registerAction } from "../redux/registerSlice";
import { useLocation, useNavigate } from "react-router-dom";

const schema = yup
    .object({
        password: yup
          .string()
          .required("")
          .min(8, "")
          .matches(/(?=.*\d)/, "")
          .matches(/(?=.*[A-Z])/, "")
          .matches(/(?=.*\W+)/, "")
          .matches(/(?=.*[a-z])/, ""),
    });

const SetPasswordContainer = () => {
    const dispatch = useDispatch()
    // const isLoading = useSelector(isSignInLoader)
    const navigate = useNavigate();
    const location = useLocation();
    const isLoading = false;
    const {register, handleSubmit, watch, resetField,
        formState: {
            isValid,
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            password: ""
        }
    });

    const passwordWatch = watch("password")


    useEffect(() => {
        if (location.search.includes("setPassword")) {
            resetField("password")
            resetField("email")
            resetField("first_name")
            resetField("last_name")
        }

    },[location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(registerAction({
            data,
            navigate,
            location
        }));
    },[dispatch, location, navigate]);

    return <SetPasswordModal isValid={isValid}
                             passwordWatch={passwordWatch}
                             isLoading={isLoading}
                             register={register}
                             onSubmit={handleSubmit(onSubmit)}/>
}

export default SetPasswordContainer
