import React from "react";
import PropTypes from "prop-types";
import "./ModalHeader.scss";
import Close from "../../Icons/Close";

const ModalHeader = ({ closeModal, children, hasCloseButton }) => {
  return (
    <div className={`modalHeader`}>
      <span className={`modalTitle`} id="modalTitle">
        {children}
      </span>
      {hasCloseButton && (
        <button
          type="button"
          aria-label="Close Modal"
          aria-labelledby="close-modal"
          className={`modalClose`}
          onClick={closeModal}
          id="closeModalId"
        >
          <Close />
        </button>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hasCloseButton: PropTypes.bool.isRequired,
};

export default ModalHeader;
