import React, { useEffect } from "react";
import ResendCodeModal from "../components/ChangeEmailModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useCallback} from "react";
import { resendCodeAction } from "../redux/resendCodeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { show } from "../../../components/core/Modal";

const schema = yup
    .object({
        email: yup.string().matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please enter a valid email address"
        ).required("Email is required"),
    });

const ResendCodeContainer = () => {
    const dispatch = useDispatch()
    // const isLoading = useSelector(isSignInLoader)
    const isLoading = false;
    const navigate = useNavigate();
    const location = useLocation();
    const {register, handleSubmit,resetField,
        formState: {
            errors,
        }
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        }
    });

    useEffect(() => {
        if (location?.search.includes("resendCode")) {
            show("resendCodeModal")
            resetField("email")
        }
    }, [location, resetField])

    const onSubmit = useCallback((data) => {
        dispatch(resendCodeAction({
            data,
            navigate,
            location
        }));
    },[dispatch, location, navigate]);

    return <ResendCodeModal isLoading={isLoading} errors={errors} register={register} onSubmit={handleSubmit(onSubmit)}/>
}

export default ResendCodeContainer
