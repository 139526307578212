import { call, put, takeEvery } from "@redux-saga/core/effects";
import registerService from "../services/resetService";
import {
  resetAction,
  resetActionError,
  resetActionSuccess
} from "./resetSlice";
import { hide, show } from "../../../components/core/Modal";
import { toast } from "react-toastify";
import resetService from "../services/resetService";


function * resetSaga ({ payload }) {
  try {

    const requestData = {
      token: payload.token,
      password: payload.data.password
    }
    const response = yield call(
      () =>
        resetService.reset(requestData),
    );
    if (response?.hasError) {
      toast.error(response.errors[0])
      yield put(resetActionError());
    } else {
      payload.navigate(`${payload.location.pathname}?sigIn`);
      yield put(resetActionSuccess());
      hide("resetPasswordModal")
      show("signInModal")

    }

  } catch (error) {
    yield put(resetActionError());
  }
}

export function * watchFetchReset () {
  yield takeEvery(resetAction.type, resetSaga);
}

